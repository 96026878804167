import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Public from '../public';
import Admin from '../admin';

export default function App() {
	return (
		<Router>
			<Route render={({location}) => {
				const isAdmin = location.pathname.includes('/administrator');

				if (isAdmin) {
					return <Admin />
				} else {
					return <Public />
				}
			}} />
		</Router>
	);
}
