import {
  ERROR_USERNAME,
	ERROR_PASSWORD,
	SESSION_TIMEOUT,
} from './_types';

export default function(type) {
	switch (type) {
		case ERROR_USERNAME: {
			return 'Invalid username';
		}
		case ERROR_PASSWORD: {
			return 'Invalid password';
		}
		case SESSION_TIMEOUT: {
			return 'Session expired';
		}
		default: {
			return type;
		}
	}
}
