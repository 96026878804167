import React from 'react'
import {NavLink} from 'react-router-dom'

const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export default function CatItem ({data,index,keypref}) {
  const key = `${keypref}__${index}`;
  const classRoot = 'project__list__item';

  // const {prevtype,prevsrc} = data;

  // if (prevtype && prevtype === 'img') {
  //   return (
  //     <NavLink key={key} to={`/project/${slugify(data.title)}`} activeClassName="current">
  //       <li className={`${classRoot}--img`}>
  //         <img src={prevsrc} alt={data.title} />
  //       </li>
  //     </NavLink>
  //   )
  // }

  return (
    <li className={classRoot}>
      <NavLink key={key} to={`/project/${slugify(data.title)}`} activeClassName="current">
        <div className={`${classRoot}__title`}><span>{data.title}</span></div>
        <div className={`${classRoot}__completed`}>{data.completed}</div>
      </NavLink>
    </li>
  )
}