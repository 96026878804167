import { createContext } from 'react';
import axios from 'axios';
import {
	TOKEN_RESET,
	TOKEN_CHECK,
	SESSION_TIMEOUT,
	LOGIN_FIELD,
	LOGIN_SUBMITTING,
	LOGIN_ERROR,
  LOGIN_SUCCESS,
	LOGOUT,
	DASHBOARD_LOADING,
	DASHBOARD_CONTENT,
	DASHBOARD_FILTER,
	DASHBOARD_NAV,
	SAVE_PROJECT_NEW,
	SAVE_PROJECT_EDIT,
	PROJECT_DELETE,
	PROJECT_DELETED,
	MSG_HIDE,
	MSG_PROJECT_NEW,
	MSG_PROJECT_SAVED,
	MSG_PROJECT_DELETED,
	ABOUT_SAVING,
	ABOUT_SAVED,
	ABOUT_MSG_DISMISS,
	ABOUT_ERROR,
} from './_types';

const localToken = () => {
	return localStorage.getItem('token');
}
export const checkToken = (token) => {  
	return new Promise ((resolve,reject) => {
		try {
			const config = {
				headers: {
					'x-auth-token': token,
				},
			};

			axios
				.get('/api/auth/user',config)
				.then(res => {
					if (res.status !== 200) {
						reject([res.msg]);
					}

					resolve(res.data)
				})
				.catch(err => {reject([err])});
			// axios
			// 	.post('/api/auth', {
			// 		username,
			// 		password,
			// 	})
			// 	.then(res => {
			// 		console.log(res)
			// 		if (res.status !== 200) {
			// 			reject(res.msg);
			// 		}

			// 		resolve(res.data);
			// 	})
			// 	.catch(err => {reject(err)})

			// resolve();
		} catch (err) {
			reject(err);
		}
	})
}
export const setToken = (token) => {
	localStorage.setItem('token',token);
}
export const removeToken = () => {
	localStorage.removeItem('token');
}

export const initialState = {
  // GLOBAL
	isLoggedIn: false,
	user: null,
	token: localToken(),
	checkingToken: false,
	// LOG IN
	login_submitting: false,
	username: '',
	password: '',
	login_errors: null,
	// DASHBOARD
	dashboard_msg: null,
	dashboard_showmsg: false,
	dashboard_loading: false,
	dashboard_nav: 'projects',
	dashboard_sort: 'title',
	dashboard_sortdir: 'asc',
	project_delete: false,
	project_delete_id: null,
  projects: null,
  about: null,
  // EDITOR
	editor_loading: false,
	// ABOUT
	about_saving: false,
	about_error: null,
	about_errormsg: null,
	about_success: false,
	about_successmsg: null,
};

export const AppReducer = (state, action) => {
	switch (action.type) {
		// LOAD PAGE
		case TOKEN_CHECK: {
			return {
				...state,
				checkingToken: true,
			}
		}
		case TOKEN_RESET: {
			return {
				...state,
				checkingToken: false,
				token: null,
				isLoggedIn: false,
				login_errors: [SESSION_TIMEOUT]
			}
		}
    // LOGIN
		case LOGIN_FIELD: {
			return {
				...state,
				[action.field]: action.value,
			};
    }
    case LOGIN_SUBMITTING: {
			return {
				...state,
				login_submitting: true,
			};
		}
		case LOGIN_ERROR: {
			return {
				...state,
				login_submitting: false,
				login_errors: action.errors,
			};
		}
		case LOGIN_SUCCESS: {
			return {
				...state,
				username: '',
				password: '',
        login_errors: null,
        login_submitting: false,
				isLoggedIn: true,
				user: action.user,
				token: action.token,
				checkingToken: false,
				dashboard_loading: true,
			};
    }
    // LOGOUT
    case LOGOUT: {
      return {
        ...state,
				isLoggedIn: false,
				user: null,
				token: null,
      }
    }
		// DASHBOARD
		case DASHBOARD_LOADING: {
			return {
				...state,
				dashboard_loading: true,
			}
		}
		case DASHBOARD_CONTENT: {
			return {
				...state,
				dashboard_loading: false,
				projects: action.projects,
				about: action.about,
			}
		}
		case DASHBOARD_FILTER: {
			return {
				...state,
				dashboard_sort: action.col,
				dashboard_sortdir: action.dir
			}
		}
		case DASHBOARD_NAV: {
			return {
				...state,
				dashboard_nav: action.payload
			}
		}
    // EDITOR
		case SAVE_PROJECT_NEW: {
			return {
				...state,
				projects: [...state.projects,action.data],
				dashboard_msg: MSG_PROJECT_NEW,
				dashboard_showmsg: true,
			}
		}
		case SAVE_PROJECT_EDIT: {
			return {
				...state,
				projects: [...state.projects.filter(p => p._id !== action.id),action.data],
				dashboard_msg: MSG_PROJECT_SAVED,
				dashboard_showmsg: true,
			}
		}
		case PROJECT_DELETE: {
			return {
				...state,
				project_delete: true,
				project_delete_id: action.id
			}
		}
		case PROJECT_DELETED: {
			return {
				...state,
				project_delete: false,
				project_delete_id: null,
				projects: [...state.projects.filter(p => p._id !== action.id),],
				dashboard_msg: MSG_PROJECT_DELETED,
				dashboard_showmsg: true,
			}
		}
		// ABOUT
		case ABOUT_SAVING: {
			return {
				...state,
				about_saving: true,
			}
		}
		case ABOUT_SAVED: {
			return {
				...state,
				about_saving: false,
				about: action.data,
				about_success: action.field,
				about_successmsg: action.msg,
			}
		}
		case ABOUT_ERROR: {
			return {
				...state,
				about_saving: false,
				about_error: action.field,
				about_errormsg: action.error
			}
		}
		case ABOUT_MSG_DISMISS: {
			return {
				...state,
				about_error: null,
				about_errormsg: null,
				about_success: false,
				about_successmsg: null,
			}
		}
		// MSG
		case MSG_HIDE: {
			return {
				...state,
				dashboard_msg: null,
				dashboard_showmsg: false
			}
		}
    // DEFAULT
		default: {
			return state;
		}
	}
};

export const AppContext = createContext();
