import React, { useState, useEffect } from "react";

// Helpers & Types
import { LOADED_THUMB } from "./_types";

// Functions
const getYoutubeId = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};
const getVimeoId = url => {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = url.match(regExp);
  return match && match[5] ? match[5] : false;
};
const urlToId = url => {
  const split = url.replace("www.", "").split(".")[0];
  if (split.length > 1) {
    const isYoutube = split.includes("youtu");
    const isVimeo = split.includes("vimeo");

    if (isYoutube) return getYoutubeId(url);
    else if (isVimeo) return getVimeoId(url);
  }
  return url;
};
const embedToSrc = (type, src) => {
  const hasSrc = src && src.length;
  const dynamicThumb = type !== "OTHER" && type !== "ABC";
  if (hasSrc && dynamicThumb) {
    const id = urlToId(src);

    if (type === "YOUTUBE") {
      return `http://img.youtube.com/vi/${id}/maxresdefault.jpg`;
    } else if (type === "VIMEO") {
      return `http://vimeo.com/api/v2/video/${id}.json`;
    }
  }
  return src;
};
const statusDefault = "No thumbnail";

export default function Thumb({ src, type, dispatch, data, setData, loading }) {
  const [thumb, setThumb] = useState(src);
  const [status, setStatus] = useState(statusDefault);

  if (type === "YOUTUBE") {
    const youtubeThumb = embedToSrc(type, src);
    if (thumb !== youtubeThumb) {
      setThumb(youtubeThumb);
    }
  } else if (type !== "VIMEO") {
    if (thumb !== src) {
      setThumb(src);
    }
  }

  useEffect(() => {
    async function fetchThumb() {
      try {
        const vimFetch = await fetch(embedToSrc("VIMEO", src));
        const vimData = await vimFetch.json();
        const thumbSrc = await vimData[0].thumbnail_large;
        if (thumb !== thumbSrc) {
          setThumb(thumbSrc);
        }
      } catch (err) {
        console.error(err);
        handleError();
      }
    }
    if (type === "VIMEO") {
      fetchThumb();
      return;
    }
    setStatus(statusDefault);
  }, [src, type]);

  return (
    <>
      {status.includes("Error") ? (
        <span>{status}</span>
      ) : thumb && thumb.length ? (
        <img
          src={thumb}
          alt="Thumbnail preview"
          onLoad={handleLoad}
          onError={handleError}
        />
      ) : (
        <span>{status}</span>
      )}
    </>
  );
  function handleLoad() {
    if (data.thumb !== thumb) {
      setData({
        ...data,
        thumb
      });
    }
    if (status !== statusDefault) {
      setStatus(statusDefault);
    }
    dispatch({
      type: LOADED_THUMB
    });
  }
  function handleError() {
    console.log("ERROR");
    dispatch({
      type: LOADED_THUMB
    });
    setStatus("Error loading image: please contact site administrator");
  }
}
