import React, { useContext, useState } from 'react';
import axios from 'axios';

// Context
import { AppContext } from '../app/context';

// Helpers & types
import {
	ABOUT_SAVING,
	ABOUT_SAVED,
	ABOUT_ERROR,
	ABOUT_MSG_DISMISS,
	ERROR_BIO,
	MSG_BIO_SAVED,
} from '../app/context/_types';

// Components
import Textarea from '../inputs/container--textarea';
import Error from './error';
import Message from './message';

// Styling
import './about.scss';

export default function BioEditor() {
	const { appState, appDispatch } = useContext(AppContext);
	const {
		about_saving,
		about_error,
		about_errormsg,
		about,
		about_success,
		about_successmsg,
	} = appState;
	const bioData = appState.about.bio;

	const currentError = about_error === 'BIO';
	const currentMsg = about_success === 'BIO';

	const [value, setValue] = useState(bioData.join('\n\n'));

	const onChange = e => {
		setValue(e.currentTarget.value);
	};
	const saveBio = () => {
		appDispatch({
			type: ABOUT_SAVING,
		});

		// Validate
		const valid = value.length > 0;
		if (!valid) {
			appDispatch({
				type: ABOUT_ERROR,
				field: 'BIO',
				error: ERROR_BIO,
			});
			return false;
		}

		async function save() {
			const token = appState.token;
			const valueArr = value.trim().split(/\n+/);
			const aboutData = {
				...appState.about,
				bio: valueArr,
			};
			const dataToSend = JSON.stringify(aboutData);
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': token,
				},
			};

			return new Promise((resolve, reject) => {
				try {
					axios
						.put(`/api/about/${about._id}`, dataToSend, config)
						.then(res => {
							if (res.status !== 200) {
								reject(res.msg);
							}

							resolve(res.data);
						})
						.catch(err => reject(err));
				} catch (err) {
					reject(err);
				}
			});
		}
    save()
      .then(data => {
        appDispatch({
          type: ABOUT_SAVED,
          data,
          field: 'BIO',
          msg: MSG_BIO_SAVED,
        });
      });
	};
	const hideMsg = () => {
		appDispatch({
			type: ABOUT_MSG_DISMISS,
		});
	};

	return (
		<div className="about__bio" data-disabled={about_saving}>
			<label>Bio</label>
			{currentError && <Error msg={about_errormsg} onClick={hideMsg} />}
			{currentMsg && <Message msg={about_successmsg} onClick={hideMsg} />}
			<Textarea
				name={'bio'}
				value={value}
				handleChange={onChange}
				saving={about_saving}
			/>
			<button
				type="button"
				className="about__bio__save"
				onClick={saveBio}
				disabled={about_saving}>
				{about_saving ? 'Saving ...' : 'Save'}
			</button>
		</div>
	);
}