// Base dependencies
import React from 'react'

// Components
import Chevron from '../_assets/icons/chevron';

export default function GroupSelect ({field,value,options,onChange}) {
  const fieldLabel = () => {
    switch(field) {
      case 'extlinktype': {
        return 'Link type';
      }
      case 'embedtype': {
        return 'Media source';
      }
      case 'prevtype': {
        return 'Preview Type';
      }
      default: {
        return null;
      }
    }
  }
  const valueLabel = () => {
    if (value) {
      return options.filter(o => (o.name === value))[0].label;
    }
    return 'Text';
  }
  const handleChange = e => {
    onChange(field,e.currentTarget.value)
  }

  if (!value) {
    value = 'website';
  }

  return (
    <div className="project__fieldgroup__field" data-type="select">
      <label>{fieldLabel()}</label>
      <div className="input__select">
        <div className="input__select__value">
          {valueLabel()}
        </div>
        <Chevron />
        <select value={value} onChange={handleChange}>
          {options.map((o,i) => <option key={i} value={o.name}>{o.label}</option>)}
        </select>
      </div>
    </div>
  )
}