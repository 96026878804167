import React, { useEffect, useState, useContext } from 'react';

import { __RouterContext } from 'react-router-dom';

import './footer.scss';
import { getTemplate } from '../globalcontext';

const year = new Date().getFullYear();

const landingThreshold = {
  portrait: 600,
  landscape: 560,
}
const parseFooterState = (layout,template) => {
	const { scrollY } = window;
	const isMob = layout === 'col-1';
	const abstractLayout = isMob ? 'portrait' : 'landscape';
	const threshold = landingThreshold[abstractLayout];
	const isLanding = template === 'landing';
	const scrollTrue = !isMob && isLanding && scrollY >= threshold;

	if (!isLanding || scrollTrue || isMob) {
		return true;
	}
	return false;
};

export default function Footer({ layout }) {
	const {location} = useContext(__RouterContext);
	const template = getTemplate(location);
	const [showFooter,setShow] = useState(parseFooterState(layout,template))

	useEffect(() => {
		const handleScroll = () => {
			const newState = parseFooterState(layout, template);

			if (newState !== showFooter) {
				setShow(newState);
			}
		};
		if (template === 'landing') {
			window.addEventListener('scroll', handleScroll);
		}
		const loadState = parseFooterState(layout, template);
		if (loadState !== showFooter) {
			setShow(loadState);
		}

		return () => window.removeEventListener('scroll', handleScroll);
	}, [template, layout, showFooter]);

	return (
		<footer data-show={showFooter}>
			<div className="footer__inner">
				<section className="footer__lhs">
					<div className="footer__title">
						<b>Noir</b> Pages
					</div>
					<div className="footer__subtitle">The portfolio of Richie Black</div>
				</section>
				<section className="footer__rhs">
					<div className="footer__copyright">{year} All rights reserved</div>
					{/* <div className="footer__colophon">
						<div className="footer__credit">
							<span className="footer__task">Design</span>
							<span className="footer__dividor"> - </span> */}
							{/* <a className="footer__name" target="_blank" href="http://google.com">Martin Baker</a> */}
							{/* <div className="footer__name">Martin Baker</div>
						</div>
					</div> */}
				</section>
			</div>
		</footer>
	);
}
