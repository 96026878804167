import React,{useState} from 'react'

export default function TestimonialTextField ({label,name,value,onChange}) {
  const [focus,setFocus] = useState(false);
  const hasVal = value.length > 0;

  return (
    <div className={`testimonial__field testimonial__${name}`} data-focus={focus}data-hasval={hasVal}>
      <label>{label}</label>
      <input 
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  )
}