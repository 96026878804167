// Base dependencies
import React, { useContext } from 'react';

// Contexts
import { AppContext } from '../app/context';

// Helpers & types
import {
	DASHBOARD_NAV,
	MSG_HIDE,
} from '../app/context/_types';

// Components
import Mark from '../_assets/logo/mark';
import Item from './item';
import Logout from '../logout';
import Messages from './messages';

// Styling
import './nav.scss';

// Definitions
const NavItems = [
	{
		id: 'projects',
		label: 'Projects',
	},
	{
		id: 'about',
		label: 'About',
	},
];

export default function Nav() {
	const { appState, appDispatch } = useContext(AppContext);
	const { dashboard_nav,dashboard_msg,dashboard_showmsg } = appState;

	const handleClick = e => {
		const newTab = e.currentTarget.getAttribute('data-tab');
		appDispatch({
			type: DASHBOARD_NAV,
			payload: newTab,
		});
	};

	const hideMsg = () => {
		appDispatch({
			type: MSG_HIDE
		})
	}

	return (
		<nav className="nav--admin">
			{dashboard_showmsg
				? <Messages msg={dashboard_msg} handleHide={hideMsg} />
				: <div className="nav__mark">
						<Mark size={80} />
					</div>
			}
			<Logout />
			<ul className="nav__list">
				{NavItems.map((item, index) => (
					<Item
						key={index}
						{...item}
						handleClick={handleClick}
						current={dashboard_nav}
					/>
				))}
			</ul>
		</nav>
	);
}
