import React,{useContext} from 'react'

import Head from '../../common/section_head'
import List from '../list'

import './landing_list.scss'
import { GlobalContext } from '../../globalcontext';

const year = new Date().getFullYear();

export default function LandingList () {
  const {appState} = useContext(GlobalContext);
  const {projects} = appState;
  
  return (
    <section id="projects" className="landing__projects">
      <Head title='Projects - Projects' subtitle={year} />
      <div className="landing__projects__lists">
        <List title='Articles & Gonzo' category='articles' projects={projects.articles}/>
        <List title='Marketing' category='marketing' projects={projects.marketing}/>
        <List title='Storytelling' category='storytelling' projects={projects.storytelling}/>
      </div>
    </section>
  )
}