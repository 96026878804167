import React from 'react'
import Subhead from '../common/subsection_head'

export default ({data}) => {
  return (
    <div className="landing__about__bio">
      <Subhead title="Bio" />
      {data.map((text,index) => <p key={`bio__${index}`}>{text}</p>)}
    </div>
  )
}