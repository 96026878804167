import React from 'react'
import {Link} from 'react-router-dom'

export default function NavDrawerItem ({title,path}) {
  const classRoot = 'project__list__item';

  return (
    <li className={classRoot}>
      <Link key={`drawer__${title}`} to={`${path}`}>
        <div className={`${classRoot}__title`}>{title}</div>
      </Link>
    </li>
  )
}