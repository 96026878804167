// Base dependencies
import React,{useState} from 'react';

// Components
import YearSelect from './yearselect'
import CheckField from './checkfield'

// Context
import {slugify} from './_reducer';

// Styling
import './fields.scss'

const categories = [
  {
    name: 'articles',
    label: 'Articles',
  },
  {
    name: 'marketing',
    label: 'Marketing',
  },
  {
    name: 'storytelling',
    label: 'Storytelling',
  },
]

export default function Field ({field,data,handleChange}) {
  const [focus,setFocus] = useState(false);

  const label = () => {
    switch(field) {
      case 'title': {
        return 'Project Title';
      }
      case 'completed': {
        return 'Completed';
      }
      case 'categories': {
        return 'Expertise';
      }
      default: {
        return '';
      }
    }
  }
  
  const onChange = e => {
    let returnData = null;

    if (field === 'title' || field === 'completed') {
      returnData = e.currentTarget.value;
    } else if (field === 'categories') {
      const name = e.currentTarget.name;
      returnData = [...data,name];
      if (data.includes(name)) {
        returnData = data.filter(c => c !== name);
      }
    }

    handleChange(field,returnData);
    if (field === 'title') {
      const alias = slugify(returnData)
      handleChange('alias',alias)
    }
  }
  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  const hasVal = () => {
    if (field === 'title') {
      return data.length > 0;
    }
  }

  const Input = () => {
    switch(field) {
      case 'title': {
        return <input type="text" name={field} onChange={onChange} value={data} onFocus=
        {onFocus} onBlur={onBlur}/>
      }
      case 'completed': {
        return <YearSelect value={data} handleChange={onChange}/>
      }
      case 'categories': {
        return categories.map((cat,ind) => {
          const shouldCheck = data.includes(cat.name);
          return <CheckField
            key={ind}
            name={cat.name}
            label={cat.label}
            value={shouldCheck}
            handleChange={onChange}
          />
        })
      }
      default:
        return null;
    }
  }

  return (
    <div className="project__field" data-field={field} data-focus={focus} data-hasval={hasVal()}>
      <label className="field__label">{label()}</label>
      {Input()}
    </div>
  )
}