import React,{useState} from 'react';

import Close from '../_assets/icons/close';

export default function Award({data,index,onChange,onRemove}) {
  const [focus,setFocus] = useState(false);
  const onFocus = () => {
    setFocus(true);
  }
  const onBlur = () => {
    setFocus(false);
  }
  const handleChange = e => {
    onChange(index,e.currentTarget.value);
  }
  const handleRemove = () => {
    onRemove(index);
  }

  return (
    <li className="award" data-focus={focus}>
      <input 
        type="text"
        value={data}
        placeholder='Award'
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        type="button"
        onClick={handleRemove}
        className="award__remove"
      >
        <Close />
      </button>
    </li>
  )
}