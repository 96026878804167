const sortTitle = (src) => {
  const neat = src
    .replace('The ', '')
    .toLowerCase()
    .replace(/"/g, '')
    .replace(/'/g, '');
  return neat;
}

export const alphSort = (src,dir='asc') => {
  const sorted = src.sort((a,b) => (dir === 'asc')
    ? ((sortTitle(a.title) < sortTitle(b.title)) ? -1 : 1)
    : ((sortTitle(a.title) < sortTitle(b.title)) ? 1 : -1)
  )
  return sorted;
}

export const catSort = (src,dir='asc') => {
  let cats = [];

  src.forEach(p => {
    const {categories} = p;
    const firstCat = categories[0];

    if (!cats.includes(firstCat)) {
      cats.push(firstCat)
    }
  })

  const catsAlph = cats.sort((a,b) => {
    return (dir === 'asc')
      ? ((a < b) ? -1 : 1)
      : ((a < b) ? 1 : -1)
  })

  const projAlph = alphSort(src,'asc')
  let returnArr = [];
  catsAlph.forEach(cat => {
    const catArr = projAlph.filter(p => p.categories.includes(cat));
    returnArr = [...returnArr,...catArr];
  })

  return returnArr;
}

export const completedSort = (src,dir='desc') => {
  return src.sort((a,b) => (
    (dir === 'desc')
      ? ((a.completed > b.completed) ? -1 : 1)
      : ((a.completed > b.completed) ? 1 : -1)
  ));
}