// Base Dependencies
import React, { useReducer, useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// Components
import Field from "./field";
import FieldGroup from "./fieldgroup";
import Embed from "./embed";
import Editor from "./editor";
import ErrorDisplay from "./errors";

// Contexts
import { AppContext } from "../app/context";

// Helpers && types
import { initialState, projectReducer, fieldDefs } from "./_reducer";
import {
  DASHBOARD_CONTENT,
  SAVE_PROJECT_NEW,
  SAVE_PROJECT_EDIT,
  MSG_HIDE
} from "../app/context/_types";
import {
  PROJECT_LOADED,
  PROJECT_SAVING,
  PROJECT_FIELD,
  PROJECT_INCOMPLETE,
  PROJECT_SAVED
} from "./_types";

// Styling
import "./ProjectEditor.scss";
import "./Editor.scss";

// Functions
const fetchProject = (id, projects) => {
  const match = projects.filter(p => {
    return p._id === id;
  });

  if (match.length > 0) {
    return match[0];
  }
  return null;
};
const validateProject = (defs, data) => {
  let errors = [];

  for (let field in defs) {
    const { validate } = defs[field];
    const value = data[field];
    const valid = validate(value);
    const dontReturn = ["alias", "completed"];

    if (field !== "prevsrc") {
      if (!valid && !dontReturn.includes(field)) errors = [...errors, field];
    }
  }

  return {
    isValid: errors.length === 0,
    errors: errors.length ? errors : null
  };
};
const parseProjectData = src => {
  const returnObj = {};

  if (src.id !== "new") {
    returnObj._id = src.id;
  }
  returnObj.title = src.title;
  returnObj.alias = src.alias;
  returnObj.completed = src.completed;
  returnObj.categories = src.categories;
  returnObj.description = src.description;

  returnObj.extlink = "";
  returnObj.extlinktype = "";
  const setExtlink = src.extlink !== undefined && src.extlink.length > 0;
  if (setExtlink) {
    returnObj.extlink = src.extlink;
    returnObj.extlinktype = src.extlinktype;
  }
  returnObj.embed = "";
  returnObj.embedtype = "";
  returnObj.embedthumb = "";

  returnObj.embed = src.embed;
  returnObj.embedtype =
    src.embed !== undefined && src.embedtype.length > 0
      ? src.embedtype
      : "OTHER";
  returnObj.embedthumb = src.embedthumb;

  return returnObj;
};

export default function ProjectEditor(props) {
  const [cancel, setCancel] = useState(false);
  const { appState, appDispatch } = useContext(AppContext);
  const { projects } = appState;
  const { id } = props.match.params;
  const isNew = id === "new";
  const data = !isNew && projects ? fetchProject(id, projects) : null;

  const [editorState, editorDispatch] = useReducer(
    projectReducer,
    initialState(projects, id, data)
  );
  const { loading, saving, errors, saved } = editorState;

  useEffect(() => {
    if (!projects && loading) {
      async function fetchData() {
        const projectData = await axios
          .get("/api/projects")
          .then(res => res.data);
        const projects = projectData;

        const aboutData = await axios.get("/api/about").then(res => res.data);
        const about = await aboutData[0];

        appDispatch({
          type: DASHBOARD_CONTENT,
          projects,
          about
        });
      }
      fetchData();
    } else if (projects && loading) {
      editorDispatch({
        type: PROJECT_LOADED,
        data: initialState(projects, id, data)
      });
    }
  }, [projects, loading, appDispatch, data, id]);
  useEffect(() => {
    appDispatch({
      type: MSG_HIDE
    });
  }, [appDispatch]);

  const handleSubmit = e => {
    e.preventDefault();

    editorDispatch({
      type: PROJECT_SAVING
    });

    // Validate
    const valid = validateProject(fieldDefs, editorState);
    if (!valid.isValid) {
      editorDispatch({
        type: PROJECT_INCOMPLETE
      });
      setErrors(valid.errors);
      return false;
    }

    async function saveProject() {
      const token = appState.token;
      const dataToSend = JSON.stringify(parseProjectData(editorState));
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token
        }
      };

      return new Promise((resolve, reject) => {
        try {
          if (isNew) {
            axios
              .post("/api/projects", dataToSend, config)
              .then(res => {
                if (res.status !== 200) {
                  reject(res.msg);
                }

                resolve(res.data);
              })
              .catch(err => reject(err));
          } else {
            axios
              .put(`/api/projects/${id}`, dataToSend, config)
              .then(res => {
                if (res.status !== 200) {
                  reject(res.msg);
                }

                resolve(res.data);
              })
              .catch(err => reject(err));
          }
        } catch (err) {
          reject(err);
        }
      });
    }
    saveProject().then(proj => {
      if (isNew) {
        appDispatch({
          type: SAVE_PROJECT_NEW,
          data: proj
        });
      } else {
        appDispatch({
          type: SAVE_PROJECT_EDIT,
          id: id,
          data: proj
        });
      }
      editorDispatch({
        type: PROJECT_SAVED
      });
    });
  };
  const handleCancel = e => {
    const rusure = window.confirm(
      "Any unsaved changes will be lost. Continue?"
    );

    rusure && setCancel(true);
  };
  const handleChange = (field, value) => {
    console.log(field, value);
    editorDispatch({
      type: PROJECT_FIELD,
      field,
      value
    });
  };
  const [projErrors, setErrors] = useState(errors);
  const updateErrors = index => {
    const reducedErrors = projErrors.filter((e, i) => i !== index);
    console.log(reducedErrors);
    setErrors(reducedErrors);
  };

  return cancel ? (
    <Redirect to="/administrator" />
  ) : saved ? (
    <Redirect to="/administrator" />
  ) : (
    <main className="page--projecteditor" data-loading={loading}>
      <form onSubmit={handleSubmit}>
        {loading ? (
          "Loading data"
        ) : (
          <>
            <div className="projecteditor__main" data-saving={saving}>
              {projErrors && (
                <ErrorDisplay
                  errorArr={projErrors}
                  updateErrors={updateErrors}
                />
              )}
              <Field
                field={"title"}
                data={editorState.title}
                handleChange={handleChange}
              />
              <Editor data={editorState} dispatch={editorDispatch} />
            </div>
            <div className="projecteditor__secondary" data-saving={saving}>
              <button
                type="submit"
                className="projecteditor__submit"
                disabled={saving}
              >
                {saving ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className="projecteditor__cancel"
                disabled={saving}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <Embed
                embed={editorState.embed}
                type={editorState.embedtype}
                thumb={editorState.embedthumb}
                handleChange={handleChange}
              />
              <FieldGroup
                type="extlink"
                data={{
                  extlink: editorState.extlink,
                  extlinktype: editorState.extlinktype
                }}
                handleChange={handleChange}
              />
              <Field
                field="categories"
                data={editorState.categories}
                handleChange={handleChange}
              />
              <Field
                field="completed"
                data={editorState.completed}
                handleChange={handleChange}
              />
            </div>
          </>
        )}
      </form>
    </main>
  );
}
