// Base dependencies
import React, { useContext } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

// Contexts
import {AppContext} from '../app/context';

// Helpers & types
import {DASHBOARD_FILTER, PROJECT_DELETE, PROJECT_DELETED} from '../app/context/_types';

// Components
import Item from './item';

// Styling
import './projects.scss';

export default function({ data }) {
  const {appState,appDispatch} = useContext(AppContext);
  const {dashboard_sort,dashboard_sortdir,project_delete,project_delete_id} = appState;

  const handleSort = e => {
    const { currentTarget } = e;
    const targetCol = currentTarget.getAttribute('data-col');
    const isCurrent = (targetCol === dashboard_sort);
    const newDir = isCurrent
      ? dashboard_sortdir === 'asc'
        ? 'desc'
        : 'asc'
      : targetCol !== 'completed'
        ? 'asc'
        : 'desc';

    appDispatch({ type: DASHBOARD_FILTER,col: targetCol, dir: newDir });
	};
	const handleDelete = id => {
		appDispatch({
			type: PROJECT_DELETE,
			id,
		})

		async function deleteProject() {
      const token = appState.token;
      const config = {
				headers: {
					'x-auth-token': token,
        }
      }

      return new Promise((resolve,reject) => {
        try {
					axios
						.delete(`/api/projects/${id}`, config)
						.then(res => {
							if (res.status !== 200) {
								reject(res.msg)
							}

							resolve(res.success);
						})
						.catch(err => reject(err))

        } catch (err) {
          reject(err)
        }
      })
    }
    deleteProject()
      .then(success => {

				appDispatch({
					type: PROJECT_DELETED,
					id,
				})
      });
	}

	return (
		<section className="dashboard__projects">
			<header>
				<h2>Projects</h2>
				<Link to="/administrator/project/new" className="button__newproject">
					<div className="title">New Project</div>
					<div className="icon">+</div>
				</Link>
			</header>

			<ul className="dashboard__projects__list">
				<li className="dashboard__projects__listhead dashboard__projects__item">
					<div
						className="dashboard__projects__item__col"
						data-col="title"
						onClick={handleSort}>
						Title
					</div>
					<div
						className="dashboard__projects__item__col"
						data-col="expertise"
						onClick={handleSort}>
						Expertise
					</div>
					<div
						className="dashboard__projects__item__col"
						data-col="completed"
						onClick={handleSort}>
						Completed
					</div>
					<div
						className="dashboard__projects__item__col"
						data-col="delete"></div>
				</li>
				{data.map((project, index) => {
					const deleting = project_delete && project_delete_id === project._id;
					return(
						<Item key={index} data={project} handleDelete={handleDelete} deleting={deleting}/>
					)
				})}
			</ul>
		</section>
	);
}
