import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export default ({data}) => {
  const markup = ReactHtmlParser(data);

  return (
    <div className="study__content">
      {markup}
    </div>
  )
}