// BAse dependencies
import React, { useState } from "react";

export default function GroupText({ name, value, onChange }) {
  const [focus, setFocus] = useState(false);

  const label = () => {
    switch (name) {
      case "extlink": {
        return "Link URL";
      }
      case "embed": {
        return "Video/Audio URL";
      }
      case "thumb": {
        return "Thumbnail URL";
      }
      default: {
        return null;
      }
    }
  };

  const onFocus = e => {
    setFocus(true);
  };
  const onBlur = e => {
    setFocus(false);
  };
  const hasVal = () => {
    return value && value.length > 0;
  };

  const handleChange = e => {
    onChange(name, e.currentTarget.value);
  };

  return (
    <div
      className="project__fieldgroup__field"
      data-focus={focus}
      data-hasval={hasVal()}
      data-type="text"
      data-field={name}
    >
      <label className="field__label">{label()}</label>
      <input
        type="text"
        name={name}
        onChange={handleChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}
