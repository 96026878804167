import React, { useReducer, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ScrollToTop from './ScrollToTop';
import { appReducer, getLayout, GlobalContext } from '../globalcontext';
import { LOAD_DATA } from '../globalcontext/_types';
import { catSort, alphSort } from '../globalcontext/_projectsort';

import Nav from '../nav';
import Footer from '../footer';

import Landing from '../routes/landing';
import About from '../routes/about';
import Project from '../routes/project';

import './public.scss';

const NoMatchPage = () => {
	return <h3>404 - Not found</h3>;
};

export default function Public () {
  const initialAppState = {
		projects: null,
		about: null,
		loadingData: true,
	};
	const [layout, setLayout] = useState(getLayout());
	const [appState, dispatch] = useReducer(appReducer, initialAppState);
	const { loadingData } = appState;

	// Load Data
	useEffect(() => {
		async function fetchData() {
			const projectData = await axios
				.get('/api/projects')
				.then(res => res.data);
			const allProjects = await alphSort(projectData);
			const categorisedProjects = await catSort(projectData);
			const aboutData = await axios.get('/api/about').then(res => res.data);
			const payloadObj = await {
				allProjects: allProjects,
				projects: categorisedProjects,
				about: aboutData[0],
			};

			dispatch({
				type: LOAD_DATA,
				payload: payloadObj,
			});
		}
		fetchData();
	}, []);

	const handleResize = () => {
		const newLayout = getLayout();

		if (newLayout !== layout) {
			setLayout(newLayout);
		}
	};
	window.onresize = handleResize;

  const transitionDuration = 1500;
  
  return (
    <Router>
      <GlobalContext.Provider value={{ appState }}>
        <ScrollToTop transitionDuration={transitionDuration}/>
        <div className="App public" data-layout={layout}>
          {!loadingData && <Nav layout={layout} />}
          <main>
            <Route render={({ location }) => (
              <TransitionGroup
                className="transition__container"
              >
                <CSSTransition
                  key={location.key}
                  timeout={transitionDuration}
                  classNames="fade"
                >
                  {loadingData ? (
                    <div className="loading">. . . LOADING . . .</div>
                  ) : (
                    <Switch location={location}>
                    {/* <Switch> */}
                      <Route path="/" exact component={Landing} />
                      <Route path="/about" exact component={About} />
                      <Route
                        path="/project/:alias"
                        exact
                        component={Project}
                      />
                      <Route component={NoMatchPage} />
                    </Switch>
                  )}
                </CSSTransition>
              </TransitionGroup>
            )}/>
          </main>
          {!loadingData && <Footer layout={layout} />}
        </div>
      </GlobalContext.Provider>
    </Router>
  )
}