// Base dependencies
import React from 'react';

// Components
import Close from '../_assets/icons/close';
import { ERROR_BIO } from '../app/context/_types';

export default function Error({ msg, onClick }) {
	const parsedError = () => {
		switch (msg) {
			case ERROR_BIO: {
				return 'Bio cannot be empty';
			}
			default:
				return 'Error undefined';
		}
	};

	return (
		<div className="dashboard__about__error">
			{parsedError()}
			<button
				type="button"
				className="dashboard__about__error__hide"
				onClick={onClick}
      >
				<Close />
			</button>
		</div>
	);
}
