import {createContext} from 'react'
import {RESIZE,TEMPLATE,NAV,FOOTER,DRAWER,LOAD_DATA} from './_types'

export const getLayout = () => {
  const col4 = window.innerWidth >= 1200;
  const col3 = window.innerWidth >= 940;
  const col2 = window.innerWidth >= 800;

  if (col4) {
    return 'col-4';
  } else if (col3) {
    return 'col-3';
  } else if (col2) {
    return 'col-2';
  }
  return 'col-1';
}
export const getTemplate = (location) => {
  const {pathname} = location;
  
  if (pathname === '/') {
    return 'landing';
  } else if (pathname === '404') {
    return '404'
  } else if (pathname.includes('/project/')) {
    return 'project'
  } else if (pathname === '/about') {
    return 'about'
  }
}

export const appReducer = (state,action) => {
  switch(action.type) {
    case RESIZE :{
      return {
        ...state,
        layout: action.payload
      }
    }
    case TEMPLATE: {
      return {
        ...state,
        template: action.payload,
        drawer: false,
        footer: false,
        nav: false,
      }
    }
    case NAV : {
      return {
        ...state,
        nav: action.payload
      }
    }
    case FOOTER : {
      return {
        ...state,
        footer: action.payload
      }
    }
    case DRAWER : {
      return {
        ...state,
        drawer: action.payload,
      }
    }
    case LOAD_DATA : {
      return {
        ...state,
        loadingData: false,
        allProjects: action.payload.allProjects,
        projects: action.payload.projects,
        about: action.payload.about
      }
    }
    default: 
      return state;
  }
}

export const GlobalContext =  createContext()