// Base dependencies
import React from 'react';

// Components
import Close from '../_assets/icons/close';

export default function Message({ msg, onClick }) {
	return (
		<div className="dashboard__about__success">
			{msg}
			<button
				type="button"
				className="dashboard__about__success__hide"
				onClick={onClick}
      >
				<Close />
			</button>
		</div>
	);
}
