// Base dependencies
import React from 'react';

// Components
import Close from '../_assets/icons/close';

export default function Messages({ msg, handleHide }) {
	return (
		<div className="dashboard__message">
			{msg}
			<button
				type="button"
				className="dashboard__message__hide"
				onClick={handleHide}>
          <Close />
        </button>
		</div>
	);
}
