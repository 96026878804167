// Base dependencies
import React from 'react'

// Components
import Chevron from '../_assets/icons/chevron';

const thisYear = new Date().getFullYear();

export default function YearSelect ({value,handleChange}) {
  const yearOptions = () => {
    let options = [];
    for(let y = thisYear; y > 2010; y-- ) {
      options.push(<option key={y} value={y}>{y}</option>)
    }
    return options;
  }
  if (!value) {
    value = 2019
  }
  return (
    <div className="input__select">
      <div className="input__select__value">
        {value}
      </div>
      <Chevron />
      <select value={value} onChange={handleChange}>
        {yearOptions()}
      </select>
    </div>
  )
}