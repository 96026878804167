import React from 'react';
import MetaTags from 'react-meta-tags';

export default function Tags ({
  title,
  description,
  url,
  image
}) {
  const root = 'http://noirpages.herokuapp.com';
  
  return (
    <MetaTags>
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="twitter:card"
        content="summary_large_image"
      />

      <title>{`Noir Pages | ${title}`}</title>
      <meta
        name="title"
        content={`Noir Pages | ${title}`}
      />
      <meta
        property="og:title"
        content={`Noir Pages | ${title}`}
      />
      <meta
        property="twitter:title"
        content={`Noir Pages | ${title}`}
      />

      <meta
        name="description"
        content={description}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="twitter:description"
        content={description}
      />

      <meta
        property="og:url"
        content={`${root}${url}`}
      />
      <meta
        property="twitter:url"
        content={`${root}${url}`}
      />
      
      <meta
        property="og:image"
        content={image || `${root}/assets/sharing/card.jpg`}
      />
      <meta
        property="twitter:image"
        content={image || `${root}/assets/sharing/card.jpg`}
      />
    </MetaTags>
  )
}