// Base dependencies
import React from 'react'

// Functions
// const parseThumb = (type,src) => {
//   switch(type) {
//     case 'YOUTUBE': {
//       return `http://img.youtube.com/vi/${src}/maxresdefault.jpg`;
//     }
//     case 'VIMEO': {
//       return `http://vimeo.com/api/v2/video/${src}.json`;
//     }
//     default:
//     case 'ABC': {
//       return src;
//     }
//   }
// }

export default ({src,title}) => {
  if (!src) {
    src = '';
  }

  return (
    <img src={src} alt={title} />
  )
}