// Base dependencies
import axios from 'axios';

// Types
import {
	ERROR_USERNAME,
	ERROR_PASSWORD,
} from '../app/context/_types';

export async function login({ username, password }) {
	return new Promise((resolve, reject) => {
		const hasUsername = username.length > 0;
		const hasPassword = password.length > 0;
		const hasBoth = hasUsername && hasPassword;

		// console.log(username,hasUsername);
		// console.log(password,hasPassword);

		if (hasBoth) {
			try {
				axios
					.post('/api/auth', {
						username,
						password,
					})
					.then(res => {
						if (res.status !== 200) {
							reject(res.msg);
						}

						resolve(res.data);
					})
					.catch(err => {
						reject(err)
					})

				// resolve();
			} catch (err) {
				reject(err);
			}
		} else {
      let errors = [];
      !hasUsername && errors.push(ERROR_USERNAME)
      !hasPassword && errors.push(ERROR_PASSWORD)
      reject(errors);
    }
	});
}
