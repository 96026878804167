// Base dependencies
import React, { useContext, useEffect } from 'react';
import axios from 'axios';

// Context
import { AppContext } from '../app/context';
import { DASHBOARD_CONTENT } from '../app/context/_types';
import { alphSort, catSort, completedSort } from '../app/context/_content';

// Components
import Nav from '../nav';
import Projects from '../projects';
import About from '../about';

// Styling
import './dashboard.scss';

export default function Dashboard() {
  const { appState, appDispatch } = useContext(AppContext);
	const {
		dashboard_nav,
		dashboard_loading,
		dashboard_sort,
		dashboard_sortdir,
		projects,
		about,
	} = appState;

	useEffect(() => {
		if (dashboard_loading) {
			async function fetchData() {
				const projectData = await axios
					.get('/api/projects')
					.then(res => res.data);
				const projects = projectData;

				const aboutData = await axios.get('/api/about').then(res => res.data);
				const about = await aboutData[0];

				appDispatch({
					type: DASHBOARD_CONTENT,
					projects,
					about,
				});
			}
			fetchData();
		}
	}, [dashboard_loading, appDispatch]);

	const sorter = col => {
		switch (col) {
			default:
			case 'title':
				return alphSort;
			case 'expertise':
				return catSort;
			case 'completed':
				return completedSort;
		}
	};
	const sortedProjects = projects
		? sorter(dashboard_sort)(projects, dashboard_sortdir)
		: null;

	return (
		<div className="page--dashboard">
			<Nav />
			<main>
				{dashboard_loading
					? <section className="page--loadingdashboard">Loading Content</section>
					: dashboard_nav === 'projects'
							? <Projects data={sortedProjects} />
							: <About data={about} />
				}
			</main>
		</div>
	);
}
