// Base dependencies
import React from 'react'

// Components
import Bio from './bio'
import Awards from './awards'
import Testimonials from './testimonials'

export default function () {
  return (
    <div className="dashboard__about">
      <Bio />
      <Awards />
      <Testimonials />
    </div>
  )
}