// Base Dependencies
import React, { useState } from "react";

// Components
import EmbedModal from "./embedmodal";

export default function Embed({ embed, type, thumb, handleChange }) {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    embed,
    type,
    thumb
  });

  const handleUpdate = () => {
    setModal(false);

    handleChange("embed", data.embed);
    handleChange("embedtype", data.type);
    handleChange("embedthumb", data.thumb);
  };
  const handleClear = e => {
    const rusure = window.confirm("Remove embedded media?");

    if (!rusure) return;
    handleChange("embed", "");
    handleChange("embedtype", "");
    handleChange("embedthumb", "");
  };
  const handleCancel = () => {
    setData({ embed, type, thumb });
    setModal(false);
  };

  return (
    <>
      <div className="project__fieldgroup">
        <label className="field__label">Hero</label>
        <div className="project__fieldgroup__btns">
          <button
            type="button"
            className="embed__toggle"
            onClick={() => setModal(true)}
          >
            {embed || thumb ? "Update" : "+ Add media"}
          </button>
          {embed ||
            (thumb && (
              <button
                type="button"
                className="embed__clear"
                onClick={handleClear}
              >
                +
              </button>
            ))}
        </div>
      </div>
      <div className="project__fieldmodal" data-show={modal}>
        <div className="modal__bg"></div>
        <div className="modal__content">
          <EmbedModal
            handleUpdate={handleUpdate}
            handleCancel={handleCancel}
            data={data}
            setData={setData}
            showing={modal}
          />
        </div>
      </div>
    </>
  );
}
