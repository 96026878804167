import React, { useContext } from 'react'
import { Redirect, __RouterContext } from 'react-router-dom'

import Meta from '../../meta'
import Header from './header'
import Content from './content'
import CTA from '../../cta'

import { GlobalContext } from '../../globalcontext'

import './study.scss'

const getProject = (projects, alias) => {
  for (var cat in projects) {
    const matches = projects[cat].filter(p => p.alias === alias)
    if (matches.length) {
      return matches[0]
    }
  }
  return false
}

export default function Study() {
  const {match,location} = useContext(__RouterContext);
  const { appState } = useContext(GlobalContext);
  const { projects, allProjects } = appState;
  const { alias } = match.params;
  const project = getProject(projects, alias);

  if (project) {
    const description = project.description.replace(/<p>/g,'').split('</p>')[0];

    return (
      <>
        <Meta
          title={project.title}
          description={description}
          url={`/project/${alias}`}
          image={project.embedthumb}
        />
        <div className="study">
          <Header data={project} />
          <Content data={project.description} />
          <CTA
            location={location}
            ctx="project"
            cats={project.categories}
            current={project._id}
            allProjects={allProjects}
          />
        </div>
      </>
    )
  }
  return <Redirect from="*" to="/404" />
}
