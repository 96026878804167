import React,{useContext} from 'react'
import {useSpring,animated} from 'react-spring'

import {GlobalContext} from '../globalcontext'

import Head from '../common/section_head'
import List from '../projects/list'
import Item from './draweritem'

export default function NavDrawer ({drawer}) {
  const {appState} = useContext(GlobalContext)
  const {projects} = appState;

  const drawerProps = useSpring({
    config: {
      tension: 300,
      clamp: true
    },
    opacity: drawer ? 1 : 0,
    transform: drawer ? 'translate3d(0,0,0)' : 'translate3d(-100%,0,0)',
    pointerEvents: drawer ? 'all' : 'none',
  })
  const year = new Date().getFullYear();

  return (
    <animated.div className="nav__drawer" style={drawerProps}>
      <div className="nav__drawer__inner">
        <ul className="nav__drawer__toplvl">
          <Head title="Richie-Black" subtitle={year} />
          <Item title='About' path='/about'/>
        </ul>
        <ul className="nav__drawer__projects">
          <Head title="Projects-Projects" subtitle={year} />
          <List title="Articles" projects={projects.articles} />
          <List title="Marketing" projects={projects.marketing} />
          <List title="Storytelling" projects={projects.storytelling} />
        </ul>
      </div>
    </animated.div>
  )
}