import React,{useState,useEffect} from 'react'

import Head from '../common/section_head'
// import Subhead from '../common/subsection_head'
import Item from '../projects/list/item'
import Contact from '../ads/contact'

import './cta.scss'

const shuffle = array => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
const compareCats = (query,against) => {
  let matches = 0;
  query.forEach(c => {
    against.includes(c) && matches++
  })
  return matches > 0;
}
const catMatch = (p,current,cats) => {
  const idMatch = (p._id === current);
  const catMatch = compareCats(p.categories,cats);
  return (!idMatch && catMatch);
}
const getList = (src,cats,current) => {
  if (cats) {
    const mustReturn = src.filter(p => {return catMatch(p,current,cats)});
    // const rest = shuffle(src.filter(p => (!mustReturn.includes(p) && p._id !== current)));
    // const concat = [...mustReturn,...rest].filter((p,i) => (i < 10));
    const concat = mustReturn.filter((p,i) => (i < 10));
    return concat;
  }
  return shuffle(src.filter(p => p._id !== current)).filter((p,i) => (i < 10))
}

export default function CTA ({location,ctx,cats,allProjects,current=null}) {
  const isProject = ctx === 'project'
  const classPre = isProject ? 'study' : 'about';
  let projTitle = 'Some - Projects';
  if (isProject) {
    const thisCat = allProjects.filter(p => p._id === current)[0].categories[0];
    if (thisCat === 'articles') {
      projTitle = 'More - Articles';
    } else if (thisCat === 'marketing') {
      projTitle = 'More - Marketing';
    } else {
      projTitle = 'More - Storytelling';
    }
  }
  const projSubtitle = new Date().getFullYear();
  const [list,setList] = useState(getList(allProjects,cats,current))
  const [currentPath,setPath] = useState(location.pathname)

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setList(getList(allProjects,cats,current))
      setPath(location.pathname)
    }
  },[location.pathname,allProjects,cats,current,currentPath])

  return (
    <div className={`${classPre}__cta`}>
      <div className="cta__projects">
        <Head title={projTitle} subtitle={projSubtitle} />
        <ul className="cta__projects__list">
          {list.map((p,i) => <Item key={`cta__${i}`} data={p}/>)}
        </ul>
      </div>
      <Contact />
    </div>
  )
}