// Base dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Close from '../_assets/icons/close';

// Helpers
import {catTitle} from './_expertise'

export default function Item({ data, handleDelete, deleting }) {
	return (
		<li className="dashboard__projects__item" data-id={data._id} data-deleting={deleting}>
			<Link to={`/administrator/project/${data._id}`}>
				<div className="dashboard__projects__item__col" data-col="title">
					{data.title}
				</div>
				<div className="dashboard__projects__item__col" data-col="expertise">
					{catTitle(data.categories[0])}
				</div>
				<div className="dashboard__projects__item__col" data-col="completed">
					{data.completed}
				</div>
			</Link>
			<div className="dashboard__projects__item__col" data-col="delete">
				<button type="button" className="project__delete" onClick={() => handleDelete(data._id)} >
					<Close />
				</button>
			</div>
		</li>
	);
}
