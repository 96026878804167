
import {
  PROJECT_LOADED,
  PROJECT_INCOMPLETE,
  PROJECT_SAVING,
  PROJECT_FIELD,
  PROJECT_SAVED,
  ERROR_TITLE,
  ERROR_CATEGORIES,
  ERROR_DESCRIPTION,
} from './_types'

export const fieldDefs = {
  // Required
  title: {
    type: String,
    validate: val => {
      return val.length > 0;
    }
  },
  alias: {
    validate: val => {
      return val.length > 0;
    }
  },
  completed: {
    validate: () => {
      return true;
    }
  },
  categories: {
    validate: val => {
      return val.length > 0;
    },
  },
  description: {
    validate: val => {
      return val.length > 0;
    },
  },
  // Existing only
  id: {
    validate: () => {
      return true;
    },
  },
  // Optional
  extlink: {
    validate: () => {
      return true;
    }
  },
  extlinktype: {
    validate: () => {
      return true;
    }
  },
  embed: {
    validate: () => {
      return true;
    }
  },
  embedtype: {
    validate: () => {
      return true;
    }
  },
  embedthumb: {
    validate: () => {
      return true;
    }
  },
  prevtype: {
    type: String,
    validate: () => {
      return true;
    }
  },
  prevsrc: {
    type: String,
    validate: val => {
      return val.length > 0;
    }
  },
}

export const errors = field => {
  switch(field) {
    case 'title': {
      return ERROR_TITLE;
    }
    case 'categories': {
      return ERROR_CATEGORIES;
    }
    case 'description': {
      return ERROR_DESCRIPTION;
    }
    default: {
      return '';
    }
  }
}

export const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const initialState = (projects,id,data) => ({
  loading: projects === null,
  saving: false,
  errors: null,
  saved: false,
  // Existing only
  id,
  isNew: id === 'new',
  // Required
  title: data ? data.title : '',
  alias: data ? data.alias : '',
  completed: data ? data.completed : new Date().getFullYear(),
  categories: data ? data.categories : [],
  description: data ? data.description : '',
  // Optional
  extlink: data ? data.extlink : '',
  extlinktype: data ? data.extlinktype : '',
  embed: data ? data.embed : '',
  embedtype: data ? data.embedtype : '',
  embedthumb: data ? data.embedthumb : '',
  prevtype: data ? data.prevtype || 'text' : 'text',
  prevsrc: data ? data.prevsrc || '' : '',
})

export const projectReducer  = (state,action) => {
  switch(action.type) {
    case PROJECT_LOADED: {
      return {
        ...state,
        loading: false,
        ...action.data,
      }
    }
    case PROJECT_INCOMPLETE: {
      return {
        ...state,
        saving: false,
      }
    }
    case PROJECT_SAVING: {
      return {
        ...state,
        saving: true
      }
    }
    case PROJECT_FIELD: {
      const {field,value} = action;
      return {
        ...state,
        [field]: value
      }
    }
    case PROJECT_SAVED: {
      return {
        ...state,
        saved: true,
      }
    }
    default: {
      return state;
    }
  }
}