// Base Dependencies
import React, { useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import Login from '../Login';
import Dashboard from '../Dashboard';
import ProjectEditor from '../ProjectEditor';

// Contexts
import {
	AppContext,
	AppReducer,
	initialState,
	checkToken,
	removeToken,
} from './context';
import { TOKEN_CHECK, TOKEN_RESET, LOGIN_SUCCESS } from './context/_types';

// Styling
import '../index.scss';
import './Admin.scss';

export default function Admin() {
	const [appState, appDispatch] = useReducer(AppReducer, initialState);
  const { isLoggedIn, checkingToken } = appState;

	useEffect(() => {
		const check = async () => {
			try {
				const auth = await checkToken(appState.token);
				appDispatch({
					type: LOGIN_SUCCESS,
					user: auth.user,
					token: appState.token,
				});
			} catch (err) {
				removeToken();
				appDispatch({ type: TOKEN_RESET });
			}
		};
		if (appState.token) {
			check();
			appDispatch({ type: TOKEN_CHECK });
		}
	}, [appState.token]);

	return checkingToken && !isLoggedIn ? (
		<div className="page--loadingsession">Loading previous session</div>
	) : (
		<Router>
			<AppContext.Provider value={{ appState, appDispatch }}>
				{isLoggedIn ? (
					<Switch>
						<Route
              path="/administrator"
              exact
              component={Dashboard}
            />
						<Route
              path="/administrator/project/:id"
              exact
              component={ProjectEditor}
            />
					</Switch>
				) : (
					<Login />
				)}
			</AppContext.Provider>
		</Router>
	);
}
