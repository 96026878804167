import React from 'react'

export default () => {
  return (
    <div className="pausebutton">
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56">
        <path d="M27 0v56H7V0h20zm30 0v56H37V0h20z"/>
      </svg>
    </div>
  )
}