export const catSort = (src) => {
  const sortAlph = alphSort(src)

  let returnObj = {};
  sortAlph.forEach(p => {
    const {categories} = p;
    const firstCat = categories[0];

    if (!returnObj[firstCat]) {
      returnObj[firstCat] = []
    }
    returnObj[categories].push(p);
  })

  return returnObj;
}
export const alphSort = (src) => {
  return src.sort((a,b) => {
    return a.title > b.title
  })
}