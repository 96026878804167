import React,{useState,useEffect,useContext} from 'react'
import {Link,__RouterContext} from 'react-router-dom'

import {getTemplate} from '../globalcontext'

import Drawer from './drawer'
import Mark from '../_assets/logo/mark'
import Toggle from './togglebutton'

import './nav.scss'

// Nav
const landingThreshold = {
  portrait: 600,
  landscape: 560,
}
const parseNavState = (template,layout,type=null) => {
  const {scrollY} = window;
  const isMob = (layout === 'col-1');
  const abstractTemplate = isMob ? 'portrait' : 'landscape';
  const threshold = landingThreshold[abstractTemplate];
  const isLanding = template === 'landing';
  const isFirstLoad = (type === 'INIT') && isLanding;
  const scrollCond = scrollY >= threshold;

  if (isLanding && !isFirstLoad && scrollCond) {
    return true;
  }
  return false;
}

export default function Nav ({layout}) {
  const {location} = useContext(__RouterContext);
  const template = getTemplate(location);

  // Scrolling
  const [navState,setNavState] = useState(parseNavState(template,layout,'INIT'));
  useEffect(() => {
    const handleScroll = () => {
      const newNavState = parseNavState(template,layout);
  
      if (newNavState !== navState) {
        setNavState(newNavState);
      }
    }
    window.addEventListener('scroll',handleScroll)
    return () => window.removeEventListener('scroll',handleScroll)
  },[template,layout,navState,setNavState])

  // Drawer toggling
  const [drawerState,setDrawerState] = useState(false);
  const onToggle = () => {
    setDrawerState(!drawerState)
  }
  useEffect(() => {
    setDrawerState(false);

    const template = getTemplate(location);
    if (template === 'landing') {
			setNavState(false);
		}
  },[location])

  return (
    <>
      <Drawer drawer={drawerState}/>
      <nav data-show={navState} data-template={template}>
        <Link to='/' className="nav__mark">
          <Mark size='100%' />
        </Link>
        <Toggle onClick={onToggle} drawer={drawerState}/>
      </nav>
    </>
  )
}