// Base dependencies
import React from 'react';

// Components
import Award from './award'

export default function AwardsEditor ({data,onChange,onRemove,addAward}) {
  return (
    <ul className="about__awards__editor">
      {data.map((award,i) => <Award key={i} data={award} index={i} onChange={onChange} onRemove={onRemove} />)}
      <button type="button" onClick={addAward} className="about__awards__new">+ Add Award</button>
    </ul>
  )
}