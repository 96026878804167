import React, { useContext, useState } from 'react';
import axios from 'axios';

// Context
import { AppContext } from '../app/context';

// Helpers & types
import {
	ABOUT_SAVING,
	ABOUT_SAVED,
	ABOUT_MSG_DISMISS,
	MSG_TESTIMONIALS_SAVED,
} from '../app/context/_types';

// Components
import Editor from './testimonialseditor';
import Message from './message';

// Styling
import './about.scss';

// Functions
const getNewVal = (index,field,newData,currentData) => {
	let returnArr = [];

	const cleanValue = (val,field) => {
		if (field !== 'quote') {
			return (val.replace(/\n/g, ''))
		}
		return val;
	}

	currentData.forEach((a,i) => {
		if (i === index) {
			returnArr.push({
				...a,
				[field]: cleanValue(newData,field),
			})
			return;
		}
		returnArr.push(a);
	})

	return returnArr;
}
const getValidTestimonials = value => {
	let returnArr = [];

	value.forEach(testimonial => {
		const validName = testimonial.name.length > 0;
		const validTitle = testimonial.title.length > 0;
		const quoteIsArray = Array.isArray(testimonial.quote)
		const quoteStr = quoteIsArray
			? testimonial.quote.join('')
			: testimonial.quote;
		const validQuote = quoteStr.trim().length > 0;

		const valid = validName && validTitle && validQuote;

		if (valid) {
			const quote = quoteIsArray ? testimonial.quote : testimonial.quote.split(/\n+/);
			const {_id,...testimonialNoId} = testimonial;

			returnArr.push({
				...testimonialNoId,
				quote,
			})
		}
	})

	return returnArr;
}

export default function TestimonialsEditor() {
	const { appState, appDispatch } = useContext(AppContext);
	const {
		about,
		about_saving,
		about_success,
		about_successmsg,
	} = appState;
	const testimonialData = about.testimonials;

	// Messages
	const currentMsg = about_success === 'TESTIMONIALS';

	// State
	const [value, setValue] = useState(testimonialData);

	// Event handlers
	const blankTestimonial = {
		quote: [''],
		name: '',
		title: '',
	}
	const addTestimonial = () => {
		setValue([...value,blankTestimonial]);
	}
	const onChange = (index,field,data) => {
		const newVal = getNewVal(index,field,data,value);
		setValue(newVal);
	};
	const onRemove = index => {
		setValue(value.filter((a,i) => i !== index));
	}
	const saveTestimonials = () => {
		appDispatch({
			type: ABOUT_SAVING,
		});

		async function save() {
			const token = appState.token;
			const valueArr = getValidTestimonials(value);

			const aboutData = {
				...appState.about,
				testimonials: valueArr,
			};
			const dataToSend = JSON.stringify(aboutData);
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': token,
				},
			};

			return new Promise((resolve, reject) => {
				try {
					axios
						.put(`/api/about/${about._id}`, dataToSend, config)
						.then(res => {
							if (res.status !== 200) {
								reject(res.msg);
							}

							resolve(res.data);
						})
						.catch(err => reject(err));
				} catch (err) {
					reject(err);
				}
			});
		}
    save()
      .then(data => {
        appDispatch({
          type: ABOUT_SAVED,
          data,
          field: 'TESTIMONIALS',
          msg: MSG_TESTIMONIALS_SAVED,
        });
      });
	};
	const hideMsg = () => {
		appDispatch({
			type: ABOUT_MSG_DISMISS,
		});
	};

	// Render
	return (
		<div className="about__testimonials" data-disabled={about_saving}>
			<label>Testimonials</label>
			{currentMsg && <Message msg={about_successmsg} onClick={hideMsg} />}
			<Editor data={value} onChange={onChange} onRemove={onRemove} addTestimonial={addTestimonial}/>
			<button
				type="button"
				className="about__testimonials__save"
				onClick={saveTestimonials}
				disabled={about_saving}>
				{about_saving ? 'Saving ...' : 'Save'}
			</button>
		</div>
	);
}