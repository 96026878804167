import React from 'react'
import {useSpring,animated} from 'react-spring'

export default function NavDrawerToggle ({drawer,onClick}) {
  const line1Props = useSpring({
    config: {
      tension: 300,
      clamp: true,
    },
    transform: drawer ? 'translate3d(0,0,0) rotateZ(45deg)' : 'translate3d(0,-5.5px,0) rotateZ(0deg)',
  })
  const line2Props = useSpring({
    config: {
      tension: 300,
      clamp: true,
    },
    transform: drawer ? 'translate3d(0,0,0) rotateZ(-45deg)' : 'translate3d(0,5.5px,0) rotateZ(0deg)',
  })



  return (
    <button type="button" className="nav__toggle" onClick={onClick}>
      <div className="nav__toggle__inner">
        <animated.div key="line_1" className="nav__toggle__line" data-line="1" style={line1Props}></animated.div>
        <animated.div key="line_2" className="nav__toggle__line" data-line="2" style={line2Props}></animated.div>
      </div>
    </button>
  )
}