import React from 'react'
import Testimonial from './testimonial'
import Subhead from '../common/subsection_head'


export default ({data}) => (
  <div className="testimonials">
    <Subhead title='Testimonials' />
    {data.map((t,i) => <Testimonial key={`testimonial__${i}`} data={t} />)}
  </div>
)