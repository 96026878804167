import React,{useState,useEffect} from 'react'

import Play from './playbutton'

import Thumb from './thumb'
import EmbedModal from './embedmodal'
import EmbedAudio from './embedaudio'

export default ({src,type,thumb,title}) => {
  const [modal,setModal] = useState(false);
  const [ready,setReady] = useState(false);

  const handleClick = ready ? (e) => {
    setModal(!modal)
  } : null;

  useEffect(() => {
    const handleKeyup = (e) => {
      if (e.keyCode === 27) {
        setModal(false)
      }
    }
    if (ready && modal) {
      window.addEventListener('keyup',handleKeyup)
    }
    else {
      window.removeEventListener('keyup',handleKeyup)
    }
  },[modal,ready])

  return (
    <>
      {
        (type !== 'OTHER')
          ? (
            <>
              <div className="study__embed__thumb" data-type="video" onClick={handleClick} data-ready={ready}>
                <Play show={ready}/>
                <Thumb src={thumb} title={title}/>
              </div>
              <EmbedModal
                type={type}
                id={src}
                show={modal}
                ready={setReady}
                toggle={setModal}
              />
            </>
          ) : (
            <div className="study__embed__thumb" data-type="audio" data-playing={modal}>
              <Thumb src={thumb} title={title}/>
              <EmbedAudio
                type={type}
                url={src}
                playing={modal}
                ready={ready}
                toggleReady={setReady}
                togglePlay={handleClick}
              />
            </div>
          )
      }
    </>
  )
}