// Base dependencies
import React, { useReducer } from "react";
import Youtube from "react-player/lib/players/YouTube";
import Vimeo from "react-player/lib/players/Vimeo";
import File from "react-player/lib/players/FilePlayer";
import ImgUploader from "./imgupload";

// Helpers & Types
import {
  LOADING_EMBED,
  LOADING_THUMB,
  LOADED_EMBED,
  LOADED_THUMB
} from "./_types";

// Components
import Text from "./grouptext";
import Thumb from "./embedthumb";

// Functions
const initialState = data => ({
  embedLoading: data.embed && data.embed.length ? true : false,
  thumbLoading: data.thumb && data.thumb.length ? true : false
});
const modalReducer = (state, action) => {
  switch (action.type) {
    case LOADING_EMBED: {
      return {
        ...state,
        embedLoading: true
      };
    }
    case LOADING_THUMB: {
      return {
        ...state,
        thumbLoading: true
      };
    }
    case LOADED_EMBED: {
      return {
        ...state,
        embedLoading: false
      };
    }
    case LOADED_THUMB: {
      return {
        ...state,
        thumbLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
const parseType = src => {
  const explode = src.replace("www.", "").split(".")[0];

  if (explode.includes("youtube") || explode.includes("youtu.be")) {
    return "YOUTUBE";
  } else if (explode.includes("vimeo")) {
    return "VIMEO";
  }
  return "OTHER";
};
const idToUrl = (type, id) => {
  if (type === "YOUTUBE") {
    if (id.includes("youtube")) return id;
    return `http://youtube.com/watch?v=${id}`;
  } else if (type === "VIMEO") {
    if (id.includes("vimeo")) return id;
    return `http://vimeo.com/${id}`;
  }
  return id;
};
const getPlayerConfig = (data, playerReady) => ({
  playing: false,
  url: idToUrl(data.type, data.embed),
  onReady: playerReady,
  width: "100%",
  height: "100%",
  controls: true
});

export default function EmbedModal({
  handleUpdate,
  handleCancel,
  data,
  setData,
  showing
}) {
  // State
  const [modalState, modalDispatch] = useReducer(
    modalReducer,
    initialState(data)
  );
  const { embedLoading, thumbLoading } = modalState;
  const isLoading = embedLoading && thumbLoading;

  // Player
  const parsedEmbed = idToUrl(data.type, data.embed);
  const playerReady = e => modalDispatch({ embedLoading: false });
  const playerConfig = getPlayerConfig(data, playerReady);
  const Player = () => {
    switch (data.type) {
      case "YOUTUBE": {
        return <Youtube {...playerConfig} />;
      }
      case "VIMEO": {
        return <Vimeo {...playerConfig} />;
      }
      default:
      case "OTHER":
      case "ABC": {
        return <File {...playerConfig} />;
      }
    }
  };

  // Thumb
  const switchThumbSrc =
    data.type === "YOUTUBE" || data.type === "VIMEO" ? parsedEmbed : data.thumb;

  // Inputs
  const handleChange = (field, value) => {
    const isEmbed = field === "embed";
    const isThumb = field === "thumb";
    const type = isEmbed ? parseType(value) : data.type;
    const thumb =
      isEmbed && type !== "OTHER" ? "" : isThumb ? value : data.thumb;

    setData({
      ...data,
      thumb,
      [field]: value,
      type
    });

    if (isEmbed && value.length) {
      modalDispatch({ type: LOADING_EMBED });

      if (type !== "OTHER") modalDispatch({ type: LOADING_THUMB });
    }
    if (isThumb) {
      modalDispatch({ type: LOADING_THUMB });
    }
  };
  const removeThumb = () => {
    setData({
      ...data,
      thumb: ""
    });
  };

  // Render
  return (
    <>
      <button
        type="button"
        className="modal__update"
        onClick={handleUpdate}
        disabled={isLoading}
      >
        Update
      </button>
      <button
        type="button"
        className="modal__cancel"
        onClick={handleCancel}
        disabled={isLoading}
      >
        Cancel
      </button>

      <div className="project__fieldgroup">
        <label className="field__label">From URL</label>
        <div className="media__preview">
          {data.embed && showing ? (
            Player()
          ) : (
            <span>Paste a url to preview media</span>
          )}
        </div>
        <Text name="embed" value={parsedEmbed} onChange={handleChange} />
      </div>
      {data.type !== "OTHER" && data.type != null && (
        <div className="project__fieldgroup">
          <div className="media__preview">
            <Thumb
              {...{
                src: switchThumbSrc,
                type: data.type,
                dispatch: modalDispatch,
                data,
                setData,
                loading: thumbLoading
              }}
            />
          </div>
        </div>
      )}

      {(data.type === "OTHER" || !data.type) && (
        <div className="project__fieldgroup">
          <label className="field__label">From File</label>
          <div className="media__preview">
            <Thumb
              {...{
                src: switchThumbSrc,
                type: data.type,
                dispatch: modalDispatch,
                data,
                setData,
                loading: thumbLoading
              }}
            />
          </div>
          {/* <Text name="thumb" value={data.thumb} onChange={handleChange} /> */}
          <ImgUploader
            value={switchThumbSrc}
            onChange={handleChange}
            onRemove={removeThumb}
            modalDispatch={modalDispatch}
            loading={thumbLoading}
          />
        </div>
      )}
    </>
  );
}
