import React from 'react'

import './section_head.scss'

export default function SectionHead ({title,subtitle}) {
  return (
    <div className="section__head">
      <div className="section__head__title">{title}</div>
      <div className="section__head__subtitle">{subtitle}</div>
    </div>
  )
}