import React, { useContext } from "react";
// import axios from "axios";

// Contexts
import { AppContext } from "../app/context";

export default function ImageUpload({
  value,
  onRemove,
  onChange,
  modalDispatch,
  loading
}) {
  const { appState } = useContext(AppContext);

  const _handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    async function uploadImg() {
      const token = appState.token;
      let formData = new FormData();
      formData.append("image", file, file.name);

      const config = {
        method: "POST",
        body: formData,
        headers: {
          "x-auth-token": token
        }
      };

      try {
        // var request = await axios.post("/upload", formData, config);
        var request = await fetch("/upload", config);
        if (request.status !== 200) {
          throw "Error: " + request.msg;
        }
        var response = await request.json();
        if (response.status === "ERROR") {
          throw response.mesage;
        }

        const { location } = response;

        onChange("thumb", location);
        modalDispatch({
          type: "LOADED_THUMB"
        });
      } catch (err) {
        console.error(err);
      }
    }

    reader.onloadend = () => {
      modalDispatch({
        type: "LOADING_THUMB"
      });
      uploadImg();
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="project__field" data-field="preview">
      <div
        className="project__field__uploader"
        data-hasimg={value.length > 0}
        data-uploading={loading}
      >
        {loading ? (
          "Loading..."
        ) : (
          <>
            <div className="project__field__uploader__input">
              <span>
                {value.length > 0 && "Change Image"}
                {value.length === 0 && "Upload Image"}
              </span>
              <input
                type="file"
                onChange={_handleImageChange}
                disabled={loading}
              />
            </div>
            {value.length > 0 && (
              <button
                className="project__field__uploader__clear"
                type="button"
                onClick={onRemove}
                disabled={loading}
              >
                Remove
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
