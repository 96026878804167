import React,{useContext} from 'react'

import Head from '../common/section_head'

import Bio from './bio'
import Awards from './awards'
import Testimonials from './testimonials'
import Contact from '../ads/contact'

import './about.scss'
import { GlobalContext } from '../globalcontext';

const year = new Date().getFullYear()

export default function About () {
  const {appState} = useContext(GlobalContext);
  const {about} = appState;

  return (
    <section id="about" className="landing__about">
      <Head title='Richie - Black' subtitle={year} />
      <div className="landing__about__inner">
        <Bio data={about.bio} />
        <Awards data={about.awards} />
        <Testimonials data={about.testimonials} />
        <Contact />
      </div>
    </section>
  )
}