import React from 'react'

import Checkbox from '../_assets/icons/checkbox';

export default function CheckField ({name,label,value,handleChange}) {

  return (
    <label className="input__check">
      <div className="checkbox">
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={handleChange}
        />
        <Checkbox checked={value} />
      </div>
      <span>{label}</span>
    </label>
  )
}