import React from 'react'

export default ({data}) => (
  <div className="testimonial">
    <div className="testimonial__quote">
      {data.quote.map((p,i) => <p key={`quote_${i}`}>{p}</p>)}
    </div>
    <div className="testimonial__name">{data.name}</div>
    <div className="testimonial__title">{data.title}</div>
  </div>
)