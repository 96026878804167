import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({transitionDuration}) {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, (transitionDuration/2))
  }, [pathname,transitionDuration]);

  return null;
}