import React from 'react'
import {useTrail,animated} from 'react-spring'

export default () => {
  const titles = ['Writer','/','Creative','/','Storyteller'];
  const trailDefs = {
    config: {
      mass: 1,
      tension: 120,
      friction: 14,
    },
    delay: 350,
    from: {
      opacity: 0,
      transform: 'translate3d(0,100%,0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    }
  }
  const trail = useTrail(titles.length, {...trailDefs})

  return (
    <div className="header__titles">
      {trail.map((props,i) => <animated.span key={i} style={props}>{titles[i]}</animated.span>)}
    </div>
  )
}