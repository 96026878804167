import React from 'react'

import Head from '../../common/subsection_head'
import Item from './item'

import './list.scss'

export default function CatList ({title,category,projects}) {
  return (
    <div className="project__group" data-category={category}>
      <Head title={title} />
      <ul className="project__list" data-category={category}>
        <div className="project__list__head">
          {(category === 'marketing') &&
            <>
              <h1>Dynamic Marketing</h1>
              <h2>I only use the [insert here] superlatives</h2>
            </>
          }
          {(category === 'articles') &&
            <>
              <h1><span>Journalism</span></h1>
              <h2><span>Good news:</span><span>It ain't dead yet.</span></h2>
            </>
          }
          {(category === 'storytelling') &&
            <>
              <h1>Want respite from reality? Try Fiction</h1>
            </>
          }
        </div>
        <div className="project__list__items">
          {(category === 'marketing') &&
            <>
              <span className="sprite sprite--topleft"></span>
              <span className="sprite sprite--top"></span>
              <span className="sprite sprite--topright"></span>
              <span className="sprite sprite--left"></span>
              <span className="sprite sprite--right"></span>
              <span className="sprite sprite--bottomleft"></span>
              <span className="sprite sprite--bottom"></span>
              <span className="sprite sprite--bottomright"></span>
              <span className="sprite sprite--middle"></span>
            </>
          }
          {(category === 'storytelling') &&
            <>
              <h2>
                <span>Existing in a universe similar to,</span>
                <span>but not quite our own.</span>
              </h2>
              <span className="sprite sprite--topleft"></span>
              <span className="sprite sprite--top"></span>
              <span className="sprite sprite--topright"></span>
              <span className="sprite sprite--left"></span>
              <span className="sprite sprite--right"></span>
              <span className="sprite sprite--bottomleft"></span>
              <span className="sprite sprite--bottom"></span>
              <span className="sprite sprite--bottomright"></span>
            </>
          }
          {
            projects.map((project,index) => (
              <Item key={`${title}__${index}`} data={project} index={index} keypref={title} />
            ))
          }
        </div>
        {(category === 'articles') &&
          <div className="project__list__media"></div>
        }
      </ul>
    </div>
  )
}