import React from 'react'

import Meta from '../../meta'
import Header from '../../landing_head'
import Projects from '../../projects/landing_list'
import About from '../../about'

import './landing.scss'

export default function Landing () {
  return (
    <>
      <Meta
        title="The portfolio of Richie Black"
        description="Writer, creative, storyteller"
        url="/"
      />
      <div className="landing">
        <Header />
        <Projects />
        <About />
      </div>
    </>
  )
}