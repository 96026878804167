import React from 'react';

export default function checkIcon ({checked}) {
  if (checked) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M4.255 6.825L3 8.053 7.034 12 16 3.228 14.745 2l-7.71 7.544-2.78-2.72zm9.967 7.397H1.778V1.778h8.889V0h-8.89C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222v-7.11h-1.778v7.11z"/>
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M14 2v12H2V2h12zm.222-2H1.778C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0z"/>
      </svg>
    )
  }
}