// Base Dependencies
import React, { useContext } from 'react';
import { login } from '../_utils';

// Components
import Mark from '../_assets/logo/mark';
import Input from '../inputs/container--text';

// Contexts
import { AppContext, setToken } from '../app/context';
import Errors from '../app/context/_errors';
import {
	LOGIN_FIELD,
	LOGIN_SUBMITTING,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
} from '../app/context/_types';

// Styling
import './login.scss';

export default function Login() {
	const { appState, appDispatch } = useContext(AppContext);
	const {
		username,
		password,
		login_errors,
		login_submitting
	} = appState;

	const handleChange = e => {
		const { currentTarget } = e;
		const field = currentTarget.name;
		const value = currentTarget.value;

		appDispatch({
			type: LOGIN_FIELD,
			field,
			value,
		});
	};
	const handleSubmit = async e => {
		e.preventDefault();

		appDispatch({ type: LOGIN_SUBMITTING });

		try {
			const auth = await login({ username, password });
			setToken(auth.token);
			appDispatch({
				type: LOGIN_SUCCESS,
				user: auth.user,
				token: auth.token,
			});
		} catch (err) {
			appDispatch({ type: LOGIN_ERROR, errors: [err.response.data.msg] });
		}
	};

	return (
		<div className="page--login">
			<form className="form--login" onSubmit={handleSubmit}>
				<div className="form--login__title">
					<Mark size={120} />
				</div>
				{login_errors && (
					<div className="form--login__errors">
						{login_errors.map((err, i) => (
							<div key={i} className="error">
								{Errors(err)}
							</div>
						))}
					</div>
				)}
				<div className="form--login__inputs">
					<Input
						name="username"
						value={username}
						label="Username"
						type="text"
						handleChange={handleChange}
					/>
					<Input
						name="password"
						value={password}
						label="Password"
						type="password"
						handleChange={handleChange}
					/>
				</div>
				<button
					type="submit"
					className="form--login__submit"
					disabled={login_submitting}>
					{login_submitting ? 'Logging In' : 'Log In'}
				</button>
			</form>
		</div>
	);
}
