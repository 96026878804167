import React from 'react'
import InputRange from 'react-input-range'

import Play from './playbutton'
import Pause from './pausebutton'

const getMinutes = (seconds) => {
  const mins = Math.floor(seconds/60).toString().padStart(2,'0');
  const secs = (parseInt(seconds)%60).toString().padStart(2,'0');
  return `${mins}:${secs}`;
}
const getProgressTime = (progressSecs,totalSecs) => {
  const current = getMinutes(progressSecs);
  const total = getMinutes(totalSecs);

  return `${current} / ${total}`;
}

export default ({playing,progress,setProgress,duration,togglePlay}) => {
  const {played,playedSeconds} = progress;
  const time = getProgressTime(playedSeconds,duration);

  const handleSeekChange = e => {
    setProgress(e/1000)
  }

  return(
    <div className="study__embed__audio__controls">
      <div className="controls__toggle" onClick={togglePlay}>
        {!playing && <Play show={true}/>}
        {playing && <Pause />}
      </div>
      <div className="controls__details">
        <div className="controls__details--title">Happy Monday</div>
        {(duration !== 0) && <div className="controls__details--time">{time}</div>}
      </div>
      {(played !== undefined) && <InputRange
        minValue={0}
        maxValue={1000}
        className="controls__progress--bar"
        onChange={handleSeekChange}
        value={Math.round(played*1000)}
      />}
    </div>
  )
}