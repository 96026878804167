import React from 'react'
import Youtube from 'react-player/lib/players/YouTube'
import Vimeo from 'react-player/lib/players/Vimeo'

export default ({type,id,show,ready,toggle}) => {
  const getIdFromUrl = (type,src) => {
    if (src.includes('/') && type === 'VIMEO') {
      const split = src.split('/');
      return split[split.length - 1];
    }
    return src;
  }
  const onReady = () => {
    ready(true)
  }
  const onEnded = () => {
    toggle(false)
  }
  const handleClick = () => {
    toggle(!show)
  }

  const vidId = getIdFromUrl(type,id);

  return (
    <div className="study__embed__modal" data-show={show} onClick={handleClick}>
      <button 
        type="button"
        className="study__embed__modal__close"
      >Close</button>
      <div className="study__embed__modal__inner">
        {(type === 'YOUTUBE') && <Youtube
          playing={show}
          url={`http://youtube.com/watch?v=${vidId}`}
          onReady={onReady}
          onEnded={onEnded}
          width='100%'
          height='100%'
        />}
        {(type === 'VIMEO') && <Vimeo
          playing={show}
          url={`http://vimeo.com/${vidId}`}
          controls={true}
          onReady={onReady}
          onEnded={onEnded}
          width='100%'
          height='100%'
        />}
      </div>
    </div>
  )
}

