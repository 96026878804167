// Base dependencies
import React, { useState, createRef, useContext } from 'react';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { ImageSideButton, Block, addNewBlock, Editor } from 'medium-draft';
import axios from 'axios';

// Helpers & types
import { PROJECT_FIELD } from './_types';
import { AppContext } from '../app/context';

// Functions
const getInitialState = description => {
	if (description && description.length) {
    const content = stateFromHTML(description);
		return EditorState.createWithContent(content);
	}
	return EditorState.createEmpty();
};
const compressMarkup = src => {
  const compressed = src
    .replace(/\s+class="([\w\s-]+)">/g, '>')
		.replace(/<p><br><\/p>/g, '')
		.replace(/<p><br\/><\/p>/g, '')
    .replace(/<p>&nbsp;<\/p>/g, '')
    .replace(/<figcaption><\/figcaption>/g, '')
    .replace(/<figure><\/figure>/g, '')
    .replace(/&#x27;/g,'\'')

	return compressed;
};

const CustomImageSideButton = (token,changeCallback) => {
	return class CustomImageSideButton extends ImageSideButton {
		onChange(e) {
			/*************************************************
      We will only check for first file and also whether
      it is an image or not.
      *************************************************/
			const file = e.target.files[0];

			if (file.type.indexOf('image/') === 0) {
				// This is a post request to server endpoint with image as `image`
				const formData = new FormData();
				formData.append('image', file);
				const url = '/upload';

				async function uploadImg() {
					let formData = new FormData();
					formData.append('file', file, file.name);

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data',
							'x-auth-token': token,
						},
					};

					return new Promise((resolve, reject) => {
						try {
							axios
								.post(url, formData, config)
								.then(res => {
									if (res.status !== 200) throw new Error(res.msg);

									resolve(res.data.path);
								})
								.catch(reject);
						} catch (err) {
							reject(err);
						}
					});
				}

				uploadImg()
					.then(path => {
						if (path) {
							// let uploadedPath = path.replace('./', '/');
							const uploadedPath = path.replace('./client/build', '');
							return this.props.setEditorState(
								addNewBlock(this.props.getEditorState(), Block.IMAGE, {
									src: uploadedPath,
                })
              );
						} else {
							throw new Error(
								'Error - did not receive uploaded image path back from server'
							);
						}
          })
          .then(_ => {
            changeCallback(this.props.getEditorState())
          })
					.catch(err => {
						alert(`Error: ${err.message}`);
					});
			} else {
				throw new Error('Error: File was not an image');
			}

			this.props.close();
		}
		render() {
			const _this = this;

			return (
				<button
					type="button"
					onClick={_this.onClick}
					title="Add image"
					className="addimage">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						viewBox="0 0 32 32">
						<path
							d="M28 20v4h4v4h-4v4h-4v-4h-4v-4h4v-4h4zM27 2v16h-2V4H2v21h16v2H0V2h27zm-4 9v7h-1v4h-4v1H4v-2l7.389-6 4.222 3L23 11zM8 6a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
						/>
					</svg>
					<input
						type="file"
						accept="'image/*"
						ref={function ref(c) {
							_this.input = c;
						}}
						onChange={_this.onChange}
						style={{ display: 'none' }}
					/>
				</button>
			);
		}
	};
};

export default function ContentEditor({ data, dispatch }) {
	const initialState = getInitialState(data.description);
	const [editorState, setEditorState] = useState(initialState);
	const editorRef = createRef();
  const { appState } = useContext(AppContext);

	const handleChange = editorState => {
    const currentContent = editorState.getCurrentContent();
    const options = {
      blockRenderers: {
        'atomic:image': (block) => {
          const data = block.getData();
          const src = data.get("src");
          const caption = block.getText();
          return `<figure><img src="${src}" alt="${caption}"/><figcaption>${caption}</figcaption></figure>`;
        },
      },
    };
    const html = stateToHTML(currentContent, options);
    // console.log(html);
    const compressedHTML = compressMarkup(html);
    // console.log(compressedHTML)

		dispatch({
			type: PROJECT_FIELD,
			field: 'description',
			value: compressedHTML,
    });
    setEditorState(editorState);
	};

	// Sidebuttons
	const sideButtons = [
		{
			title: 'Image',
			component: CustomImageSideButton(appState.token, handleChange),
		},
	];

	// Get rid of toolbar
	const toolbarConfig = {
		block: [],
		inline: [],
	};

	return (
		<div className="project__editor__draftjs">
			<Editor
				ref={editorRef}
				editorState={editorState}
				onChange={handleChange}
				sideButtons={sideButtons}
				toolbarConfig={toolbarConfig}
			/>
		</div>
	);
}
