import React,{useState} from 'react'

export default function TestimonialTextareaField ({label,name,value,onChange}) {
  const [focus,setFocus] = useState(false);
  const hasVal = Array.isArray(value)
    ? value.join('').length > 0
    : value.length > 0;

  let valStr = value;
  if (Array.isArray(value)) {
    valStr = value.join('\n\n')
  }

  return (
    <div className={`testimonial__field testimonial__${name}`} data-focus={focus}data-hasval={hasVal}>
      <label>{label}</label>
      <textarea
        name={name}
        value={valStr}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  )
}