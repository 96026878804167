import React from 'react'

import Mark from '../_assets/logo/mark'
import Titles from './titles'

import './landing_head.scss'

export default function LandingHead() {

  return (
    <header className="landing__header">
      <div className="landing__header__mark">
        <Mark size="100%" />
      </div>
      <div className="landing__header__text">
        <h1>
          <b>Noir</b> <br />
          Pages
        </h1>
        <h2>
          The portfolio of
          <br /> Richie Black
        </h2>
        <Titles />
      </div>
    </header>
  )
}
