import React,{useContext} from 'react'

import { GlobalContext } from '../../globalcontext';

import Header from './header'
import Bio from '../../about/bio'
import Awards from '../../about/awards'
import Testimonials from '../../about/testimonials'
import Contact from '../../ads/contact'

import Meta from '../../meta';

import './about.scss'
import '../../about/about.scss'

const About = () => {
  const {appState} = useContext(GlobalContext);
  const {about} = appState;

  return (
    <>
      <Meta
        title="About"
        description={about.bio[0]}
        url="/about"
      />
      <div className="about">
        <Header />
        <main className="about__inner">
          <Bio data={about.bio} />
          <Awards data={about.awards} />
          <Testimonials data={about.testimonials} />
          <Contact />
        </main>
      </div>
    </>
  )
}

export default About
