import React from 'react';

import Close from '../_assets/icons/close';
import Text from './testimonialtext'
import Textarea from './testimonialtextarea'

export default function Testimonial({data,index,onChange,onRemove}) {
  const {quote,name,title} = data;

  // Update value
  const handleChange = e => {
    const target = e.currentTarget;
    const field = target.name;
    const val = target.value;
    onChange(index,field,val);
  }

  // Remove
  const handleRemove = () => {
    onRemove(index);
  }

  return (
    <li className="testimonial">
      <Textarea
        label='Quote'
        name='quote'
        value={quote}
        onChange={handleChange}
      />
      <Text
        label='Name'
        name='name'
        value={name}
        onChange={handleChange}
      />
      <Text
        label='Position/title'
        name='title'
        value={title}
        onChange={handleChange}
      />
      <button
        type="button"
        onClick={handleRemove}
        className="testimonial__remove"
      >
        <Close />
      </button>
    </li>
  )
}