import React, { useContext } from 'react';

import { LOGOUT } from '../app/context/_types';
import { AppContext, removeToken } from '../app/context';

export default function Logout() {
	const { appDispatch } = useContext(AppContext);

	const handleClick = e => {
		removeToken();
		appDispatch({ type: LOGOUT });
	};
	return (
		<button className="nav__logout" type="button" onClick={handleClick}>
			Log Out
		</button>
	);
}
