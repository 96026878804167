import React from 'react'

const trophy = require('./_assets/trophy@2x.jpg')

export default ({data}) => {
  return (
    <div className="awards">
      <div className="awards__head">Awards</div>
      <div className="awards__content">
        <div className="awards__text">
          <div className="awards__text__intro">Because sometimes people really like what I do</div>
          <ul className="awards__text__awards">
            {data.map((award,index) => <li key={`award__${index}`}className="awards__award">{award}</li>)}
          </ul>
        </div>
        <div className="awards__media">
          <img src={trophy} alt="Trophy" />
        </div>
      </div>
    </div>
  )
}