export const PROJECT_LOADED = 'PROJECT_LOADED';
export const PROJECT_INCOMPLETE = 'PROJECT_INCOMPLETE';
export const PROJECT_SAVING = 'PROJECT_SAVING';
export const PROJECT_FIELD = 'PROJECT_FIELD';
export const PROJECT_SAVED = 'PROJECT_SAVED';

export const LOADING_EMBED = 'LOADING_EMBED';
export const LOADING_THUMB = 'LOADING_THUMB';
export const LOADED_EMBED = 'LOADED_EMBED';
export const LOADED_THUMB = 'LOADED_THUMB';

export const ERROR_TITLE = 'Project title cannot be empty';
export const ERROR_CATEGORIES = 'Project must have at least one area of expertise set';
export const ERROR_DESCRIPTION = 'Project description cannot be left empty';