import React, { useContext, useState } from 'react';
import axios from 'axios';

// Context
import { AppContext } from '../app/context';

// Helpers & types
import {
	ABOUT_SAVING,
	ABOUT_SAVED,
	ABOUT_MSG_DISMISS,
	MSG_AWARDS_SAVED,
} from '../app/context/_types';

// Components
import Editor from './awardseditor';
import Message from './message';

// Styling
import './about.scss';

// Functions
const getNewVal = (index,newData,currentData) => {
	let returnArr = [];

	const cleanValue = val => (val.trim().replace(/\n/g, ''))

	currentData.forEach((a,i) => {
		if (i === index) {
			returnArr.push(cleanValue(newData))
			return;
		}
		returnArr.push(cleanValue(a));
	})

	return returnArr;
}

export default function AwardsEditor() {
	const { appState, appDispatch } = useContext(AppContext);
	const {
		about,
		about_saving,
		about_success,
		about_successmsg,
	} = appState;
	const awardsData = about.awards;

	// Messages
	const currentMsg = about_success === 'AWARDS';

	// State
	const [value, setValue] = useState(awardsData);

	// Event handlers
	const addAward = () => {
		setValue([...value,'']);
	}
	const onChange = (index,data) => {
		const newVal = getNewVal(index,data,value);
		setValue(newVal);
	};
	const onRemove = index => {
		setValue(value.filter((a,i) => i !== index));
	}
	const saveAwards = () => {
		appDispatch({
			type: ABOUT_SAVING,
		});

		async function save() {
			const token = appState.token;
			const valueArr = value.filter(val => val.length > 0);

			const aboutData = {
				...appState.about,
				awards: valueArr,
			};
			const dataToSend = JSON.stringify(aboutData);
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': token,
				},
			};

			return new Promise((resolve, reject) => {
				try {
					axios
						.put(`/api/about/${about._id}`, dataToSend, config)
						.then(res => {
							if (res.status !== 200) {
								reject(res.msg);
							}

							resolve(res.data);
						})
						.catch(err => reject(err));
				} catch (err) {
					reject(err);
				}
			});
		}
    save()
      .then(data => {
        appDispatch({
          type: ABOUT_SAVED,
          data,
          field: 'AWARDS',
          msg: MSG_AWARDS_SAVED,
        });
      });
	};
	const hideMsg = () => {
		appDispatch({
			type: ABOUT_MSG_DISMISS,
		});
	};

	// Render
	return (
		<div className="about__awards" data-disabled={about_saving}>
			<label>Awards</label>
			{currentMsg && <Message msg={about_successmsg} onClick={hideMsg} />}
			<Editor data={value} onChange={onChange} onRemove={onRemove} addAward={addAward}/>
			<button
				type="button"
				className="about__awards__save"
				onClick={saveAwards}
				disabled={about_saving}>
				{about_saving ? 'Saving ...' : 'Save'}
			</button>
		</div>
	);
}