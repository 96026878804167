// Base dependencies
import React from 'react';

// Components
import CloseIcon from '../_assets/icons/close';

// Helpers & types
import { errors } from './_reducer';

export default function Errors({ errorArr, updateErrors }) {
	return (
		<div className="errors">
			{errorArr.map((err, index) => (
				<div key={`error_${index}`} className="errors__error">
					{errors(err)}
					<button
						type="button"
						className="errors__error__remove"
						onClick={() => updateErrors(index)}>
						<CloseIcon />
					</button>
				</div>
			))}
		</div>
	);
}
