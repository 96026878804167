import React,{useState,useEffect,useRef} from 'react'
import File from 'react-player/lib/players/FilePlayer'

import AudioControls from './audiocontrols'

export default ({url,playing,ready,toggleReady,togglePlay}) => {
  const [playState,setPlayState] = useState(playing);
  const [played,setPlayed] = useState({played: 0,playedSeconds: 0});
  const [duration,setDuration] = useState(0);

  const player = useRef();

  useEffect(() => {
    setPlayState(playing)
  },[playing])

  const onReady = () => {
    toggleReady(true)
  }
  const handleScrub = (prog) => {
    player.current.seekTo(prog)
  }
  const handleEnd = () => {
    setPlayState(false)
    player.current.seekTo(0)
  }

  return (
    <>
      <div className="study__embed__audio" data-ready={ready}>
        <File
          ref={player}
          playing={playState}
          url={url}
          onReady={onReady}
          width='100%'
          height='100%'
          onProgress={setPlayed}
          onDuration={setDuration}
          onEnded={handleEnd}
        />
        <AudioControls
          playing={playState}
          progress={played}
          duration={duration}
          togglePlay={togglePlay}
          setProgress={handleScrub}
        />
      </div>
    </>
  )
}

