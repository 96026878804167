// Base dependencies
import React,{useState} from 'react';

// Styling
import './inputs.scss'

export default function InputContainerText({
	name,
	label,
	value,
	type,
  handleChange,
}) {
	const [focus,setFocus] = useState(false);

	return (
		<div 
			className="input__container"
			data-hasval={value.length > 0}
			data-focus={focus}
		>
			<label>{label}</label>
			<input
        type={type}
				name={name}
				value={value}
				onChange={handleChange}
				onFocus={() => {setFocus(true)}}
				onBlur={() => {setFocus(false)}}
			/>
		</div>
	);
}
