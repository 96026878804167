import React from 'react'

import Thumb from './thumb'
import Embed from './embed'

const LinkIcon = (<svg xmlns="http://www.w3.org/2000/svg" className="study__extlink__icon" width="28" height="28" viewBox="0 0 28 28">
<path fillRule="evenodd" d="M15 6a3 3 0 0 1 3 3v1h-2V9a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-1h2v1a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h9zm6 3l6 5-6 5-.001-4H8v-2h12.999L21 9z"/>
</svg>)

const ExtLink = ({href,type}) => {
  const label = () => {
    const prefix = 'View';

    switch(type) {
      case 'website': {
        return `${prefix} website`;
      }
      case 'review': {
        return `${prefix} review`;
      }
      default: {
        return `${prefix} project`;
      }
    }
  }

  return (
    <a href={href} className="study__extlink" target="_blank" rel="noopener noreferrer">
      <div className="study__extlink__content">
        <div className="study__extlink__label">{label()}</div>
        {LinkIcon}
      </div>
    </a>
  )
}
const capitalise = (src) => {
  return src.map(c => {
    const first = c.slice(0,1).toUpperCase();
    const rest = c.slice(1);
    return `${first}${rest}`;
  })
}

export default ({data}) => {
  const hasLink = (data.extlink !== undefined) && (data.extlink.length > 0);
  const capCats = capitalise(data.categories)
  const extlink = hasLink
    ? data.extlink.slice(0,4).includes('http')
      ? data.extlink
      : `http://${data.extlink}`
    : null;

  return (
    <header className="study__head">
      {(data.embed.length > 0) &&
        <Embed src={data.embed} type={data.embedtype} thumb={data.embedthumb} title={data.title}/>
      }
      {(data.embed.length === 0 && data.embedthumb.length > 0) &&
        <div className="study__embed__thumb" data-ready={true} data-type="thumb">
          <Thumb src={data.embedthumb} title={data.title}/>
        </div>
      }
      <div className="study__pretitle">Projects</div>
      <div className="study__title" data-haslink={hasLink}>
        <h1>{data.title}</h1>
        {hasLink && <ExtLink href={extlink} type={data.extlinktype}/>}
      </div>
      <div className="study__meta">
        <div className="study__meta__field" data-field="cat">
          <div className="study__meta__field__label">Expertise</div>
          <div className="study__meta__field__value">{capCats.join(', ')}</div>
        </div>
        <div className="study__meta__field" data-field="completed">
          <div className="study__meta__field__label">Completed</div>
          <div className="study__meta__field__value">{data.completed}</div>
        </div>
      </div>
    </header>
  )
}