// Base dependencies
import React from 'react';

// Components
import Testimonial from './testimonial'

export default function TestimonialsEditor ({data,onChange,onRemove,addTestimonial}) {
  return (
    <ul className="about__testimonials__editor">
      {data.map((t,i) => <Testimonial key={i} data={t} index={i} onChange={onChange} onRemove={onRemove} />)}
      <button type="button" onClick={addTestimonial} className="about__testimonials__new">+ Add Testimonial</button>
    </ul>
  )
}