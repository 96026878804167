export const ERROR_USERNAME = 'ERROR_USERNAME';
export const ERROR_PASSWORD = 'ERROR_PASSWORD';
export const ERROR_BIO = 'ERROR_BIO';

export const TOKEN_CHECK = 'TOKEN_CHECK';
export const TOKEN_RESET = 'TOKEN_RESET';

export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

export const LOGIN_FIELD = 'LOGIN_FIELD';
export const LOGIN_SUBMITTING = 'LOGIN_SUBMITTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_CONTENT = 'DASHBOARD_CONTENT';
export const DASHBOARD_FILTER = 'DASHBOARD_FILTER';
export const DASHBOARD_NAV = 'DASHBOARD_NAV';

export const SAVE_PROJECT_NEW = 'SAVE_PROJECT_NEW';
export const SAVE_PROJECT_EDIT = 'SAVE_PROJECT_EDIT';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETED = 'PROJECT_DELETED';

export const ABOUT_SAVING = 'ABOUT_SAVING';
export const ABOUT_SAVED = 'ABOUT_SAVED';
export const ABOUT_ERROR = 'ABOUT_ERROR';
export const ABOUT_MSG_DISMISS = 'ABOUT_MSG_DISMISS';

export const LOGOUT = 'LOGOUT';

export const MSG_HIDE = 'MSG_HIDE';
export const MSG_PROJECT_NEW = 'New project added';
export const MSG_PROJECT_SAVED = 'Project updated';
export const MSG_PROJECT_DELETED = 'Project deleted';
export const MSG_BIO_SAVED = 'Bio saved';
export const MSG_AWARDS_SAVED = 'Awards saved';
export const MSG_TESTIMONIALS_SAVED = 'Testimonials saved';