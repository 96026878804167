// Base dependencies
import React from 'react';

// Components
import Text from './grouptext';
import Select from './groupselect';
import ImgUpload from './imgupload';

// Styling
import './fields.scss'

const extlinktypes = [
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'review',
    label: 'Review',
  },
]
const prevtypes = [
  {
    name: 'text',
    label: 'Text',
  },
  {
    name: 'img',
    label: 'Image',
  },
]
const label = (type) => {
  switch(type) {
    case 'extlink': {
      return 'Project/Review Link';
    }
    case 'embed': {
      return 'Embedded Media';
    }
    case 'preview': {
      return 'List Preview';
    }
    default: {
      return '';
    }
  }
}

export default function FieldGroup ({type,data,handleChange}) {
  
  const onChange = (field,value) => {
    handleChange(field,value);
  }

  return (
    <div className="project__fieldgroup" data-field={type}>
      <label className="field__label">{label(type)}</label>
      {type === 'extlink' &&
        <>
          <Select
            field='extlinktype'
            value={data.extlinktype}
            options={extlinktypes}
            onChange={onChange}
          />
          <Text
            name='extlink'
            value={data.extlink}
            onChange={onChange}
          />
        </>
      }
      {type === 'embed' &&
        <Text
          name='embed'
          value={data.embed}
          onChange={onChange}
        />
      }
      {type === 'preview' &&
        <>
          <Select
            field='prevtype'
            value={data.prevtype}
            options={prevtypes}
            onChange={onChange}
          />
          {data.prevtype && data.prevtype === 'img' && ImgUpload(handleChange,data.prevsrc)}
        </>
      }
    </div>
  )
}